/**
 * <PRE>
 * System Name : 사랑온 NEXT 시스템(TSS2-MSP)
 * Business Name : 청약
 * Class Name : PSDocFormUtil.js
 * Description :
 *              AS-IS 파일 PSDocFormUtil.js 대응
 *              전자서명 앱 호출 공통 유틸
 * </PRE>
 */

import PSDateUtil from '~/src/ui/ps/comm/PSDateUtil'
import PSXmlConvertUtil from '~/src/ui/ps/comm/PSXmlConvertUtil'
import PdfConstants from '@/config/constants/pdfConstants'
import PSConstants from '@/config/constants/psConstants'
import PSServiceManager from '~/src/ui/ps/comm/PSServiceManager'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'


const DocForm = {}

/************************************************************************************************
 * Function명 : returnJsonData
 * 설명       : 전자서명 앱 결과 정보 구조
 ************************************************************************************************/
const returnJsonData = {
  'signature': false, // 전자서명 최종 완료 여부 (true : 최종 완료, false : 미완료
  'strXmlData': {}, // PDF에서 추출한 XMl DATA 원본
  'xmlData': {}, // PDF에서 추출한 XMl DATA 가공
  'signerInfo': {
    'isSameContractor': '', // true : 계피동일 , false : 계피상이
    'signerDetail': [] // 사인 정보
  },
  'fileCrc': '', // 파일 CRC 값
  'trtRslt': 1, // 에러코드 0 실패, 1 성공
  'trtRsltMsg': '' // 에러 메세지
}

/************************************************************************************************
 * Function명 : baseJsonData
 * 설명       : 전자서명 앱 기본 정보 구조
 ************************************************************************************************/
const baseJsonData = {
  'signLogYN': 'N', // 로그파일 저장 여부 (사랑On 에서 N으로 세팅)
  'user_id': '', // FC 사번, 재서명일때 고려 직접 로그인 정보 아이디 셋팅
  'preview': false, // 미리보기 여부 (true:미리보기, false:전자서명)
  'pdf_name': '', // PDF 저장 경로 및 파일이름
  'ozd_name': '', // 임시저장파일이름
  'xml_data': '', // 사랑on 에서 전달해주는 Xml Data(미리보기 발행 전문)
  'enc_key': '', // 암호화 키
  'enc_state': true, // 암호화 여부 (true:암호화, false:비암호화)
  'client_id': '', // 타임스탬프 ID - for TimeStamp
  'pdf_doc_id': '', // PDF 문서 고유 ID - for TimeStamp
  'svctype': 'PS', //
  'is_dev': '0', // 타임스탬프 서버IP 구분(개발계/검증계=0, 운영계=1) - for TimeStamp
  'pdf_data': '', // 전자서명 정보 -for 중간저장
  'category': PdfConstants.CATAGORY_PROPERTY, // catagory.property
  'pvmnotice': {}, // 청약 알릴고지 관련
  'condition': [], // 전자 교부방법 (나무 팝업)
  'add_input_data': [], // 입력된 전자서명 정보
  'signerInfo': {
    'isSameContractor': '', // true : 계피동일 , false : 계피상이
    'isBnfrChg': '', // 분할: 1, 연금: 2, 연금후순위: 3, 만기: 4, 상해: 5, 사망1: 6, 사망2: 7, 사망3: 8, 사망4: 9
    'signerDetail': [] // 사인 정보
  },
  'jsonInputData': {} // 발행전문
  // 'ozParam': PdfConstants.OZ_PARAM,
  // 'ozParamSep': PdfConstants.OZ_PARAM_SEP
}
/************************************************************************************************
 * Function명 : conditionJsonData
 * 설명       : 전자 교부방법 구조
 ************************************************************************************************/
const conditionJsonData = {
  'TYPE': 'OFR', // 사랑On 하드 코딩
  'CMMUSCCD': '', // 통신사
  'CELNO': '', // 전화번호
  'EMAIL': '', // email
  'YY': '', // 년
  'MM': '', // 월
  'DD': '', // 일
  'HH': '', // 시간
  'NN': '', // 분
  'APM': '', // AM/PM
  'TEXTDATA': '', // ex) 2018/10/01 14:30에 휴대폰으로 인증하셨습니다.
  'LMSFSTISUMTHSCCD': '', // 교부방법 01 : email, 02 : 서면 ,03 : 모바일+Email , 04 : 모바일
  'SUITA': '' // 적합성 질문지, 설문지일경우 Y
}
/************************************************************************************************
 * Function명 : addInputBaseInfoJsonData
 * 설명       : 계약자/피보험자 기본 정보 구조
 ************************************************************************************************/
const addInputBaseInfoJsonData = {
  'signerCode': '', // 사인(1:계약자, 2:피보험자, 3:'', 6:예금주, 9: FC)
  'conm': '', // 근무처/회사명
  'indclnm': '', // 업종명
  'jobnm': '', // 하시는일(구체적으로)
  'celno': '', // 계약자전화번호
  'smsReciCnsntYn': '', // 문자서비스수신동의여부(Y/N)
  'smartReciCnsntYn': '', // 스마트서비스수신동의여부(Y/N)
  'homTelno': '', // 집전화번호
  'email': '', // Email 주소
  'emailReciCnsntYn': '', // 이메일서비스수신동의여부 Y/M
  'basMailRcppl': '', // 기본우편물수령처
  'addr': '' // 집/회사주소
}

/************************************************************************************************
 * Function명 : addInputContInfoJsonData
 * 설명       : 계약자 추가 정보 구조
 * [ASR210701056] 금소법 관련 시스템 반영 요청(확인서2종, 개인_사랑온_사전입력 화면)
 * : 전문가금융소비자여부,일반금융소비자동일대우여부 추가
 ************************************************************************************************/
const addInputContInfoJsonData = {
  'insrSecusRcvMth': '', // 보험증권받는방법  1 방문전달, 2 자택일반우편, 3 직장일반우편, 4 이메일, 5 콜센터(자택), 6 콜센터(직장), 7 낱장(본사), 8 낱장(지점)
  'oversPtaxYn': '', // 해외거주자/납세의무자여부 (Y/N)
  'dwCntrySc': 'N', // 거주구분 (Y/N)
  'exprtFncCspYn': '', // 전문가금융소비자여부
  'genFncCspSameTrtmtYn': '' // 일반금융소비자동일대우여부
}

/************************************************************************************************
 * Function명 : addInputInsrdInfoJsonData
 * 설명       : 피보험자 추가 정보 구조
 ************************************************************************************************/
const addInputInsrdInfoJsonData = {
  'insrdSex': 'M', // 피보험자성별  M:남자/F:여자
  'aisdSex': 'M', // 종피보험자성별 M:남자/F:여자
  'ubent': 'N' // 태아여부 Y:태아/N:태아아님
}

/************************************************************************************************
 * Function명 : addInputBaseFATCAInfoJsonData
 * 설명       : 계약자 FATC 정보 구조
 ************************************************************************************************/
const addInputBaseFATCAInfoJsonData = {
  'usaDwlrYn': '', // 미국거주자 여부 (Y/N)
  'usaDwlrSc': '', // 미국거주자 구분 (미국시민권자 : 01 / 미국영주권자 : 02 / 미국세법상미국거주자 : 03)
  'etcCntryDwlrYn': '', // 한/미외 해외거주자 (Y/N)
  'engNm01': '', // 영문성
  'engNm02': '', // 영문이름
  'engAddr': '', // 영문주소
  'taxsPpsDmclCntry1': '', // 조세목적상 거주지 국가 1
  'ptaxNo1': '', // 납세자번호 1
  'ptaxNoUnInptRsn1': '', // 납세자번호 미기재 사유 1
  'taxsPpsDmclCntry2': '', // 조세목적상 거주지 국가 2
  'ptaxNo2': '', // 납세자번호 2
  'ptaxNoUnInptRsn2': '', // 납세자번호 미기재 사유 2
  'taxsPpsDmclCntry3': '', // 조세목적상 거주지 국가 3
  'ptaxNo3': '', // 납세자번호 3
  'ptaxNoUnInptRsn3': '', // 납세자번호 미기재 사유 3
  'unAcqRsn': '' // 미취득 사유
}
/************************************************************************************************
 * Function명 : addInputBankInfoJsonData
 * 설명       : 계약자 계좌 정보 구조
 ************************************************************************************************/
const addInputBankInfoJsonData = {
  'aftRctmHopYn': '', // 후입금 여부(Y:후입금, N: 초회입금)
  'bnkNm': '', // 금융기관명
  'bnkAccnno': '', // 계좌번호
  'dpstrnm': '', // 예금주명
  'dpstrDob': '', // 예금주생년월일
  'rtpMth': '', // 제1회보험료실시간출금이체신청여부
  'atrAplYn': '', // 제2회이후보험료자동이체및실시간출금이체신청여부
  'trsfObjCd': '', // 이체대상코드 (01:보험료,  02:보험료+이자)
  'trsfHopddCd': '', // 이체희망일코드 (05:5일, 10:10일, 15:15일, 20:20일, 25:25일)
  'atRmtnApl': '' // 분할-배당-연금/휴면 보험금 자동송금 신청여부 (Y/N)
}

/************************************************************************************************
 * Function명 : signerDetailJsonData
 * 설명       : 전자서명 사인 여부 정보 구조
 ************************************************************************************************/
const signerDetailJsonData = {
  'signerTitle': '', // 계약자 , 피보험자, Fc등 화면에 표시될 네이밍(실제 홍길등으로 표기도 가능)
  'signerCode': '', // 계약자 : 1, 피보험자 : 2, 예금주 : 6, FC : 9(추후 추가 가능)
  'authorization': false, // 인증정보 ==> 이번에 사인을 실행 할지 판단 근거
  'complete': false // 서명 완료 정보
}
/************************************************************************************************
 * Function명 : conditionDocForm
 * 설명       : 전자 교부방법 정보 세팅
 * @param signerData
 ************************************************************************************************/
const conditionDocForm = function (signerData, eltrnSaveData, contInfoData) {
  // 전자 교부방법 정보 세팅
  let condition = Object.assign({}, conditionJsonData)
  if (signerData.contrAthntYmd.trim() !== '') { // 계약자 정보가 있을때만 세팅
    let contrAthntYmd = signerData.contrAthntYmd
    let contrAthntTm = signerData.contrAthntTm
    // 20191107 : 계약일자 기준 변경
    // let insrdAthntYmd = signerData.insrdAthntYmd !== undefined && !piCommonUtil.isEmpty(signerData.insrdAthntYmd) ? signerData.insrdAthntYmd : ''
    // let insrdAthntTm = signerData.insrdAthntTm !== undefined && !piCommonUtil.isEmpty(signerData.insrdAthntTm) ? signerData.insrdAthntTm : ''
    condition.TYPE = 'OFR' // 사랑On 하드 코딩
    condition.CMMUSCCD = '' // 통신사
    condition.CELNO = contInfoData.celno // 전화번호
    condition.EMAIL = contInfoData.emailAddr // email
    // 20191107 : 계약일자 기준 변경
    // if (!piCommonUtil.isEmpty(insrdAthntYmd) && contrAthntYmd < insrdAthntYmd) {
    //   condition.YY = String(insrdAthntYmd).substring(0, 4) // 년
    //   condition.MM = String(insrdAthntYmd).substring(4, 6) // 월
    //   condition.DD = String(insrdAthntYmd).substring(6, 8) // 일
    //   condition.HH = String(insrdAthntTm).substring(0, 2) // 시간
    //   condition.NN = String(insrdAthntTm).substring(2, 4) // 분
    // } else {
    //   condition.YY = String(contrAthntYmd).substring(0, 4) // 년
    //   condition.MM = String(contrAthntYmd).substring(4, 6) // 월
    //   condition.DD = String(contrAthntYmd).substring(6, 8) // 일
    //   condition.HH = String(contrAthntTm).substring(0, 2) // 시간
    //   condition.NN = String(contrAthntTm).substring(2, 4) // 분
    // }
    //
    //
    // 20191107 : 계약일자 기준 변경
    // 계약일 기준
    // 1. 실시간 이체 O 계약일 = 계좌정보제공일자 : 초회보험료납입방법코드:1(실시간이체), 출금동의일자
    // 2. 실시간 이체 X 계약일 = 전자서명완료일자 : 모바일영업지원 서버시간
    // signerData 조회 흐름
    // 1) TSSPS180M.vue > fn_init > fn_BaseDataSetting
    // 2) PS180MUtil.js > fn_EltrnSignDocService > fn_EltrnSignServiceData('S1')
    // 3) txTSSPS98S1 > this.setItemData.signerData = lv_data
    // 4) TSSPS180M.vue > fn_PdfViewerPlugin
    // 5) PSDocFormUtil.js > setJsonData > conditionDocForm
    let contYmdTm = signerData.svrDtm
    if (signerData.frtmPrmPadMthCd === '1') {
      contYmdTm = signerData.dfraCnsntYmd + signerData.dfraCnsntDtm
    }
    condition.YY = String(contYmdTm).substring(0, 4) // 년
    condition.MM = String(contYmdTm).substring(4, 6) // 월
    condition.DD = String(contYmdTm).substring(6, 8) // 일
    condition.HH = String(contYmdTm).substring(8, 10) // 시간
    condition.NN = String(contYmdTm).substring(10, 12) // 분

    // 계약자 인증정보 ex) 2018/10/01 14:30에 휴대폰으로 인증하셨습니다.
    // condition.TEXTDATA = condition.YY + '년 ' + condition.MM + '월 ' + condition.DD + '일 ' +
    //   condition.HH + ':' + condition.NN + ':' + tmpDateSecond + ' 에 ' +
    // (signerData.contrAthntMethCd === 'C' ? '휴대폰' : '신용카드') + ' (으)로 인증 하셨습니다.'
    // 191126 : 계약자의 인증방식코드가 있는 경우에만 문구를 표시함
    //        : 피보험자가 먼저서명할때 계약자의 인증 문구는 표시하지 않음
    condition.TEXTDATA = ''
    condition.contrAthntMethCd = ''
    if (signerData.contrAthntMethCd.trim() !== '') {
      condition.TEXTDATA = '계약자는 ' + String(contrAthntYmd).substring(0, 4) + '년 ' +
      String(contrAthntYmd).substring(4, 6) + '월 ' +
      String(contrAthntYmd).substring(6, 8) + '일 ' +
      String(contrAthntTm).substring(0, 2) + ':' + String(contrAthntTm).substring(2, 4) + ':' + String(contrAthntTm).substring(4, 6) + ' 에 ' +
      // (signerData.contrAthntMethCd === 'C' ? '휴대폰' : '신용카드') + ' (으)로 인증 하셨습니다.'
      // 190930 : 인증방법 추가 : 'I':신분증
      (signerData.contrAthntMethCd === 'C' ? '휴대폰' : signerData.contrAthntMethCd === 'M' ? '신용카드' : '신분증') + ' (으)로 인증 하셨습니다.'
      // 보험거래문서디지털화 : 인증방법코드 추가
      condition.contrAthntMethCd = signerData.contrAthntMethCd
    } else {
      condition.TEXTDATA = ''
    }

    // 주피보험자 인증정보 200109 추가
    condition.TEXTDATA2 = ''
    condition.insrdAthntMethCd = ''
    if (signerData.insrdAthntMethCd.trim() !== '' && signerData.insrdAthntMethCd.trim() !== 'P') {
      condition.TEXTDATA2 = '피보험자는 ' + String(signerData.insrdAthntYmd).substring(0, 4) + '년 ' +
      String(signerData.insrdAthntYmd).substring(4, 6) + '월 ' +
      String(signerData.insrdAthntYmd).substring(6, 8) + '일 ' +
      String(signerData.insrdAthntTm).substring(0, 2) + ':' + String(signerData.insrdAthntTm).substring(2, 4) + ':' + String(signerData.insrdAthntTm).substring(4, 6) + ' 에 ' +
      // (signerData.contrAthntMethCd === 'C' ? '휴대폰' : '신용카드') + ' (으)로 인증 하셨습니다.'
      // 190930 : 인증방법 추가 : 'I':신분증
      (signerData.insrdAthntMethCd === 'C' ? '휴대폰' : signerData.insrdAthntMethCd === 'M' ? '신용카드' : '신분증') + ' (으)로 인증 하셨습니다.'
      // 보험거래문서디지털화 : 인증방법코드 추가
      condition.insrdAthntMethCd = signerData.insrdAthntMethCd
    } else {
      condition.TEXTDATA2 = ''
    }

    // 예금주 인증정보 200109 추가
    condition.TEXTDATA6 = ''
    condition.dpstrAthntMethCd = ''
    if (signerData.dpstrAthntMethCd.trim() !== '') {
      condition.TEXTDATA6 = '예금주는 ' + String(signerData.dpstrAthntYmd).substring(0, 4) + '년 ' +
      String(signerData.dpstrAthntYmd).substring(4, 6) + '월 ' +
      String(signerData.dpstrAthntYmd).substring(6, 8) + '일 ' +
      String(signerData.dpstrAthntTm).substring(0, 2) + ':' + String(signerData.dpstrAthntTm).substring(2, 4) + ':' + String(signerData.dpstrAthntTm).substring(4, 6) + ' 에 ' +
      (signerData.dpstrAthntMethCd === 'C' ? '휴대폰' : signerData.dpstrAthntMethCd === 'M' ? '신용카드' : '신분증') + ' (으)로 인증 하셨습니다.'
      // 보험거래문서디지털화 : 인증방법코드 추가
      condition.dpstrAthntMethCd = signerData.dpstrAthntMethCd
    } else {
      condition.TEXTDATA6 = ''
    }

    let hours = Number(condition.HH)
    if (hours >= 12 && hours <= 23) {
      condition.APM = 'PM' // AM/PM
      condition.HH = (hours === 12 ? '12' : String(hours % 12))
      if (condition.HH.length !== 2) {
        condition.HH = ('0' + condition.HH)
      } // end if
    } else {
      condition.APM = 'AM' // AM/PM
      condition.HH = hours === 24 ? '12' : String(hours)
    } // end else if
    let isContrAthntMeth = signerData.contrAthntMethCd.trim() !== ''
    // 교부방법 01 : email, 02 : 서면 ,03 : 모바일+Email , 04 : 모바일
    if (isContrAthntMeth) {
      condition.LMSFSTISUMTHSCCD = eltrnSaveData.isuMthScCd
    } else {
      condition.LMSFSTISUMTHSCCD = ' '
    } // end else if
  } // end if
  return condition
}
/************************************************************************************************
 * Function명 : contInfoDocForm
 * 설명       : 계약자 정보 세팅
 * @param contInfoData
 * @param eltrnSaveData
 * @param fatcaData
 * @param bankData
 * @param isAthnt
 * @param cardData
 ************************************************************************************************/
const contInfoDocForm = function (contInfoData, eltrnSaveData, fatcaData, bankData, isAthnt, dpstrInfo, cardData) {
  // 계약자 정보 세팅
  let addInputContInfo = Object.assign({}, baseInfoDocForm('1', contInfoData, eltrnSaveData, isAthnt, dpstrInfo))
  // 추가 정보
  Object.assign(addInputContInfo, addInputContDocForm(eltrnSaveData))
  // FATCA 정보 입력
  Object.assign(addInputContInfo, addFATCAInfoDocForm(eltrnSaveData, fatcaData))
  // 금융 정보
  Object.assign(addInputContInfo, addContBankInfoDocForm(eltrnSaveData, bankData, contInfoData, cardData))

  return addInputContInfo
}


/************************************************************************************************
 * Function명 : addAmlInfoDocForm
 * 설명       : Aml 서식 기본정보 셋팅
 * @param amlCustContRltnTypCd '1' 계약자 , '2' 대리인(친권자) , '3' 수익자(사망)
 * @param custInfo 채널고객정보 
 ************************************************************************************************/
const addAmlInfoDocForm = function (amlCustContRltnTypCd,custInfo){
  let rtnData = {}
  let preFix = getPrefixAmlCustContRltn(amlCustContRltnTypCd) 

  let custNm = '',custRrn = '',custEngNm = '',cntryNm   = '' , knbScCd = ''
  let custTelno = '',custEmailAddr = '', homAddr = '', jobpAddr  = '',tempAddr = ''
  
  if(custInfo){
    custNm        = custInfo.custNm
    custRrn       = PSCommUtil.fn_KnbFormat(custInfo.knb,true)
    custTelno     = custInfo.celno    // "010-1234-1234"
    custEmailAddr  = custInfo.emailAddr 

    knbScCd       = custInfo.knbScCd //'ZPER01'? '주민등록번호' : '외국인등록번호'
    
    //영문명, 국적의 경우 외국인의 경우만 출력
    if(knbScCd != 'ZPER01'){
      custEngNm     = custInfo.custEngNm
      cntryNm       = custInfo.bpNatyNm
    }  
    
    //homFrnoPstcd 우편번호
    tempAddr = custInfo.homAddr? String(custInfo.homAddr).trim() : ''
    if(tempAddr){
      custInfo.bldgNo01    = custInfo.bldgNo01  ? String(custInfo.bldgNo01).trim()    : ''
      custInfo.homDtlad   = custInfo.homDtlad   ? String(custInfo.homDtlad).trim()   : ''
      custInfo.adtnAddr01 = custInfo.adtnAddr01 ? String(custInfo.adtnAddr01).trim() : ''

      homAddr  = custInfo.homAddr
      homAddr += custInfo.bldgNo01    ? ' '+ custInfo.bldgNo01  : ''
      homAddr += custInfo.homDtlad   ? ' '+ custInfo.homDtlad   : ''
      homAddr += custInfo.adtnAddr01 ? ' '+ custInfo.adtnAddr01 : ''
    }

    //custInfo.jobpFrnoPstcd 우편번호
    tempAddr = custInfo.jobpAddr? String(custInfo.jobpAddr).trim() : ''
    if(tempAddr){
      custInfo.bldgNo02   = custInfo.bldgNo02   ? String(custInfo.bldgNo02).trim()   : ''
      custInfo.jobpDtlad  = custInfo.jobpDtlad  ? String(custInfo.jobpDtlad).trim()  : ''
      custInfo.adtnAddr02 = custInfo.adtnAddr02 ? String(custInfo.adtnAddr02).trim() : ''

      jobpAddr  = custInfo.jobpAddr
      jobpAddr += custInfo.bldgNo02   ? ' '+ custInfo.bldgNo02   : ''
      jobpAddr += custInfo.jobpDtlad  ? ' '+ custInfo.jobpDtlad  : ''
      jobpAddr += custInfo.adtnAddr02 ? ' '+ custInfo.adtnAddr02 : ''
    }
  }
  rtnData[preFix + 'custNm']        =  custNm/*고객성명*/
  rtnData[preFix + 'custRrn']       = custRrn /*고객실명번호(주민번호) */
  rtnData[preFix + 'custEngNm']     = custEngNm /*영문명*/
  rtnData[preFix + 'cntryNm']       = cntryNm /*국가(국적)명 외국인경우 */
  rtnData[preFix + 'custTelno']     = custTelno /*핸드폰번호*/
  rtnData[preFix + 'custEmailAddr'] = custEmailAddr /*이메일*/
  rtnData[preFix + 'homAddr']       = homAddr /*자택주소*/
  rtnData[preFix + 'jobpAddr']      = jobpAddr /*직장주소*/
  //rtnData[preFix + 'fpNm']          = PSServiceManager.getUserInfo('userNm')?PSServiceManager.getUserInfo('userNm') : '' // 모집FC명
  
  return rtnData
}


/************************************************************************************************
 * Function명 : mnisdInfoDocForm
 * 설명       : 피보험자 정보 세팅
 * @param mnisdInfoData
 * @param signerData
 * @param eltrnSaveData
 * @param hoshInfoList
 * @param isAthnt
 ************************************************************************************************/
const mnisdInfoDocForm = function (mnisdInfoData, signerData, eltrnSaveData, hoshInfoList, isAthnt, dpstrInfo) {
  // 피보험자 정보 세팅
  let addInputMnisdInfo = Object.assign({}, baseInfoDocForm('2', mnisdInfoData, eltrnSaveData, isAthnt))
  // 추가 정보
  Object.assign(addInputMnisdInfo, addInputInsrdDocForm(signerData, hoshInfoList, eltrnSaveData)) // eltrnSaveData 추가(ASR210600884_20210720_PJO)

  return addInputMnisdInfo
}
/************************************************************************************************
 * Function명 : prtrDocForm
 * 설명       : 친권자 정보 세팅 / (ASR210600884_20210720_PJO)
 * @param signerData
 ************************************************************************************************/
const prtrDocForm = function (eltrnSaveData, hoshInfoList) {
  // isPRTR_YN, OZ1_isPRTR_YN  동일값 이지만 각 각 세팅한다.
  // 사유 : 동일값이 지만 서식에따라 필드명만 다름 (여러서식에서 혼재되서 사용)
  let addInputPrtr = {
    'signerCode': '', // 4 친권자
    'isPRTR_YN': '', // 주피.친권구분
    'OZ1_isPRTR_YN': '', // 주피.친권구분
    'OZ2_isPRTR_YN': '', // 종피.친권구분
    'PRTR_Rel_01_PS': '', // 친권1.관계
    'PRTR_Rel_02_PS': '' // 친권2.관계
  }

  let isPRTR_txt = '' // 0  공동  ,  1 단독 (모청과 동일값)\
  let PRTR_Rel_01_PS_txt = '' // 친권1.관계
  let PRTR_Rel_02_PS_txt = '' // 친권2.관계
  if ( eltrnSaveData.prtrInsrdRltnScCd02 !== undefined && eltrnSaveData.prtrInsrdRltnScCd02 !== '' ) {
    if (eltrnSaveData.prtrInsrdRltnScCd02 === '90' ) { // 90 1인친권
      isPRTR_txt = '1'
    } else {
      isPRTR_txt = '0'
    }
  }
  if ( eltrnSaveData.prtrInsrdRltnScCd01 !== undefined && eltrnSaveData.prtrInsrdRltnScCd01 !== '' ) {
    switch (eltrnSaveData.prtrInsrdRltnScCd01) {
      case '20':
        PRTR_Rel_01_PS_txt = '부'
        break
      case '21':
        PRTR_Rel_01_PS_txt = '모'
        break
      default:
        break
    }
  }
  if ( eltrnSaveData.prtrInsrdRltnScCd02 !== undefined && eltrnSaveData.prtrInsrdRltnScCd02 !== '' ) {
    switch (eltrnSaveData.prtrInsrdRltnScCd02) {
      case '20':
        PRTR_Rel_02_PS_txt = '부'
        break
      case '21':
        PRTR_Rel_02_PS_txt = '모'
        break
      default:
        break
    }
  }
  // 종피가 존재하면 친권구분
  let aisdInfo = hoshInfoList.filter(item => item.contvInsrdCd === '23')
  if (aisdInfo && aisdInfo.length > 0) {
    addInputPrtr.OZ2_isPRTR_YN = isPRTR_txt // 친권구분
  }
  addInputPrtr.signerCode = '4' // 4 친권자
  addInputPrtr.isPRTR_YN = isPRTR_txt // 친권구분
  addInputPrtr.OZ1_isPRTR_YN = isPRTR_txt // 친권구분
  addInputPrtr.PRTR_Rel_01_PS = PRTR_Rel_01_PS_txt // 친권1.관계
  addInputPrtr.PRTR_Rel_02_PS = PRTR_Rel_02_PS_txt // 친권2.관계
  return addInputPrtr
}
/************************************************************************************************
 * Function명 : baseInfoDocForm
 * 설명       : 계약자/피보험자 기본 정보 세팅
 * @param signerData
 * @param infoData
 * @param eltrnSaveData
 * @param isAthnt
 ************************************************************************************************/
const baseInfoDocForm = function (signerCode, infoData, eltrnSaveData, isAthnt, dpstrInfo) {
  let addInputBaseInfo = Object.assign({}, addInputBaseInfoJsonData)
  addInputBaseInfo.signerCode = signerCode // 사인(1:계약자, 2:피보험자, 3: 9: FC)
  addInputBaseInfo.celno = infoData.celno // 모바일 전화번호

  if (signerCode === '1') { // 계약자 구분
    addInputBaseInfo.conm = eltrnSaveData.contrJobpNm // 근무처/회사명
    addInputBaseInfo.indclnm = eltrnSaveData.contrIndclNm // 업종명
    addInputBaseInfo.jobnm = eltrnSaveData.contrDtlBusnNm // 하시는일(구체적으로)
    addInputBaseInfo.smsReciCnsntYn = eltrnSaveData.contrSmsReciCnsntYn // 문자서비스수신동의여부(Y/N)
    addInputBaseInfo.smartReciCnsntYn = eltrnSaveData.contrSmartReciCnsntYn // 스마트수신동의여부(Y/N)
    addInputBaseInfo.emailReciCnsntYn = eltrnSaveData.contrEltrnMalReciYn // 이메일서비스수신동의여부 Y/M
    addInputBaseInfo.basMailRcppl = eltrnSaveData.mailRcpplCd.trim() // 기본우편물수령처
    // 200109 예금주 정보 추가
    let dpstrRltnNm = ''
    if (eltrnSaveData.dpstrRltnCd === '00') {
      dpstrRltnNm = '본인'
    } else if (eltrnSaveData.dpstrRltnCd === '02') {
      dpstrRltnNm = '배우자'
    } else if (eltrnSaveData.dpstrRltnCd === '03') {
      dpstrRltnNm = '자녀'
    } else if (eltrnSaveData.dpstrRltnCd === '04') {
      dpstrRltnNm = '부모'
    }
    // 예금주 동의전 계약자, 피보험자 서명가능
    if (dpstrInfo !== undefined ) {
      addInputBaseInfo.dpstrnm = dpstrInfo.custNm // 예금주 이름
      addInputBaseInfo.dpstrdob = PSDateUtil.getFullBirthDate(dpstrInfo.knb) // 예금주 생년월일
      addInputBaseInfo.dpstrfmrtnm = dpstrRltnNm // 예금주 가족관계명
      addInputBaseInfo.dpstrcelno = dpstrInfo.celno // 예금주 연락처
    }
  } else if (signerCode === '2') { // 피보험자 구분
    addInputBaseInfo.conm = eltrnSaveData.insrdJobpNm // 근무처/회사명
    addInputBaseInfo.indclnm = eltrnSaveData.insrdIndclNm // 업종명
    addInputBaseInfo.jobnm = eltrnSaveData.insrdDtlBusnNm // 하시는일(구체적으로)
    addInputBaseInfo.smsReciCnsntYn = eltrnSaveData.insrdSmsReciCnsntYn // 문자서비스수신동의여부(Y/N)
    addInputBaseInfo.smartReciCnsntYn = eltrnSaveData.insrdSmartReciCnsntYn // 스마트수신동의여부(Y/N)
    addInputBaseInfo.emailReciCnsntYn = eltrnSaveData.insrdEltrnMalReciYn // 이메일서비스수신동의여부 Y/M
    addInputBaseInfo.basMailRcppl = eltrnSaveData.insrdMailRcpplCd.trim() // 기본우편물수령처
  } // end else if
  if (isAthnt) {
    addInputBaseInfo.homTelno = infoData.homTelno // 집전화번호
    addInputBaseInfo.email = infoData.emailAddr // Email 주소
    let addrData = ''
    if (addInputBaseInfo.basMailRcppl === '01') { // 01 자택, 02: 직장
      if (addInputBaseInfo.homAddrScCd === '1') { // 지번
        addrData = '(' + infoData.homFrnoPstcd + ') ' +
        infoData.homAddr + ' ' + infoData.homDtlad
      } else { // 도로명주소
        addrData = '(' + infoData.homFrnoPstcd + ') ' +
        infoData.homAddr + ' ' + infoData.bldgNo01 + ' ' + infoData.adtnAddr01 + ' ' + infoData.homDtlad
      } // end else if
    } else {
      if (addInputBaseInfo.jobpAddrScCd === '1') { // 지번
        addrData = '(' + infoData.jobpFrnoPstcd + ') ' +
        infoData.jobpAddr + ' ' + infoData.jobpDtlad
      } else { // 도로명주소
        addrData = '(' + infoData.jobpFrnoPstcd + ') ' +
        infoData.jobpAddr + ' ' + infoData.bldgNo02 + ' ' + infoData.adtnAddr02 + ' ' + infoData.jobpDtlad
      } // end else if
    } // end else if
    // 주소가 없을때 () 제거
    addInputBaseInfo.addr = addrData.replace('()', ' ')
  } // end if
  return addInputBaseInfo
}
/************************************************************************************************
 * Function명 : addInputContDocForm
 * 설명       : 계약자 추가 정보 세팅
 * @param eltrnSaveData
 * [ASR210701056] 금소법 관련 시스템 반영 요청(확인서2종, 개인_사랑온_사전입력 화면) : 필드 추가
 ************************************************************************************************/
const addInputContDocForm = function (eltrnSaveData) {
  let addInputContInfo = Object.assign({}, addInputContInfoJsonData)
  /* 주석처리 (20210907_PJO)
  // 보험증권받는방법  1 방문전달, 2 자택일반우편, 3 직장일반우편, 4 이메일, 5 콜센터(자택), 6 콜센터(직장), 7 낱장(본사), 8 낱장(지점) M모바일
  // 모바일일경우 '0' 제외 이외는 추가
  if (eltrnSaveData.secusCvancMthCd !== 'M') {
    addInputContInfo.insrSecusRcvMth = '0' + eltrnSaveData.secusCvancMthCd
  } else {
    addInputContInfo.insrSecusRcvMth = eltrnSaveData.secusCvancMthCd
  }
  */
  // ASR210800597_증권수령방법 변경 (20210907_PJO)
  // DB저장 : M 모바일만선택,  4 이메일만선택, A 모바일+지류, B 이메일+지류 (TTSD00002.SECUS_CVANC_MTH_CD)
  // 양식전달 : M 모바일만선택, 04 이메일만선택, A 모바일+지류, B 이메일+지류 (pnbau1.insrSecusRcvMth)
  if (eltrnSaveData.secusCvancMthCd === '4' || eltrnSaveData.secusCvancMthCd === '7') { // 7 고액/브로셔 /  중단건양식에서 보여줘야하기때문에 남겨 둠
    addInputContInfo.insrSecusRcvMth = '0' + eltrnSaveData.secusCvancMthCd
  } else {
    addInputContInfo.insrSecusRcvMth = eltrnSaveData.secusCvancMthCd
  }
  // TODO FATCA 값 N 이라도 Y로 처리 해서 보냄 임시 조치 유니닥스에서 처리 완료후 해제 해야함
  addInputContInfo.oversPtaxYn = eltrnSaveData.fatcaCnfmObjYn // 해외거주자/납세의무자여부 (Y/N)
  addInputContInfo.dwCntrySc = eltrnSaveData.contrDwScCd // 계약자거주구분코드

  // 지정대리청구인_2인가능_수정_START(ASR210500282_2021.05.31 PJO)
  addInputContInfo.G06 = '' // 지정대리청구인 지정값 : G06 / 2자리코드세팅  - 01(1인지정), 02(2인지정), M1(미지정), M2(지정불가)
  addInputContInfo.POLHLDR_NM_021 = '' // 지정대리청구인1(대표) 성명 : POLHLDR_NM_021, 피보험자와의 관계 : POLHLDR_REL_021
  addInputContInfo.POLHLDR_REL_021 = ''
  addInputContInfo.POLHLDR_NM_022 = '' // 지정대리청구인2     성명 : POLHLDR_NM_022, 피보험자와의관계 : POLHLDR_REL_022
  addInputContInfo.POLHLDR_REL_022 = ''
  let contrRltnCd = {
    '01': '본인',
    '02': '배우자',
    '03': '자녀',
    '04': '부모',
    '05': '기타가족',
    '06': '친족',
    //ASR240200999_간편지대청 도입(김문준/240423) / 간편: 배우자=11/아들=12/딸=13 
    '11': '배우자',
    '12': '아들',
    '13': '딸',
  }
  // G06 - 01(1인지정), 02(2인지정), M1(미지정), M2(지정불가)
  addInputContInfo.G06 = eltrnSaveData.apcPrsonAplScCd
  if (eltrnSaveData.apcAsgnYn === 'Y') {
    // addInputContInfo.G06 = eltrnSaveData.apcPrsonAplScCd
    if (eltrnSaveData.apcPrsonAplScCd === '01') { // 01(1인지정)
      addInputContInfo.POLHLDR_NM_021 = DocForm.textToBase64(eltrnSaveData.apcCustNm)
      addInputContInfo.POLHLDR_REL_021 = DocForm.textToBase64(contrRltnCd['' + eltrnSaveData.contrRltnCd])
    } else if (eltrnSaveData.apcPrsonAplScCd === '02') { // 02(2인지정)
      // 1.대표
      addInputContInfo.POLHLDR_NM_021 = DocForm.textToBase64(eltrnSaveData.apcCustNm)
      addInputContInfo.POLHLDR_REL_021 = DocForm.textToBase64(contrRltnCd['' + eltrnSaveData.contrRltnCd])
      // 2.
      addInputContInfo.POLHLDR_NM_022 = DocForm.textToBase64(eltrnSaveData.apcCustNm02)
      addInputContInfo.POLHLDR_REL_022 = DocForm.textToBase64(contrRltnCd['' + eltrnSaveData.contrRltnCd02])
    }
  }

  // [ASR210701056] 금소법 관련 시스템 반영 요청(확인서2종, 개인_사랑온_사전입력 화면)
  addInputContInfo.exprtFncCspYn = eltrnSaveData.exprtFncCspYn // 전문가금융소비자여부
  addInputContInfo.genFncCspSameTrtmtYn = eltrnSaveData.genFncCspSameTrtmtYn // 일반금융소비자동일대우여부

  console.log('PSDocFormUti..apcPrsonAplScCd [' + eltrnSaveData.apcPrsonAplScCd + ']' )
  console.log('PSDocFormUti..apcCustNm [' + eltrnSaveData.apcCustNm + ']' )
  console.log('PSDocFormUti..contrRltnCd [' + eltrnSaveData.contrRltnCd + ']' )
  console.log('PSDocFormUti..apcCustNm02 [' + eltrnSaveData.apcCustNm02 + ']' )
  console.log('PSDocFormUti..contrRltnCd02 [' + eltrnSaveData.contrRltnCd02 + ']' )
  console.log('PSDocFormUti.addInputContInfo.G06 [' + addInputContInfo.G06 + ']' )
  console.log('PSDocFormUti.addInputContInfo.POLHLDR_NM_021 [' + addInputContInfo.POLHLDR_NM_021 + ']' )
  console.log('PSDocFormUti.addInputContInfo.POLHLDR_REL_021 [' + addInputContInfo.POLHLDR_REL_021 + ']' )
  console.log('PSDocFormUti.addInputContInfo.POLHLDR_NM_022 [' + addInputContInfo.POLHLDR_NM_022 + ']' )
  console.log('PSDocFormUti.addInputContInfo.POLHLDR_REL_022 [' + addInputContInfo.POLHLDR_REL_022 + ']' )
  // ASIS
  // addInputContInfo.G06 = ''
  // addInputContInfo.POLHLDR_NM_02 = ''
  // addInputContInfo.POLHLDR_Rel_02 = ''
  // // 지정대리청구인 정보를 앱에 넘겨줌
  // if (eltrnSaveData.apcAsgnYn === 'Y') {
  //   addInputContInfo.G06 = 'Y'
  //   addInputContInfo.POLHLDR_NM_02 = DocForm.textToBase64(eltrnSaveData.apcCustNm)
  //   let contrRltnCd = {
  //     '01': '본인',
  //     '02': '배우자',
  //     '03': '자녀',
  //     '04': '부모',
  //     '05': '기타가족',
  //     '06': '친족'
  //   }
  //   addInputContInfo.POLHLDR_Rel_02 = DocForm.textToBase64(contrRltnCd['' + eltrnSaveData.contrRltnCd])
  // }
  return addInputContInfo
}
/************************************************************************************************
 * Function명 : addInputInsrdDocForm
 * 설명       : 피보험자 추가 정보 세팅
 * @param eltrnSaveData
 * @param hoshInfoList
 ************************************************************************************************/
const addInputInsrdDocForm = function (signerData, hoshInfoList, eltrnSaveData) { // eltrnSaveData 추가(ASR210600884_20210720_PJO)
  let addInputInsrdInfo = Object.assign({}, addInputInsrdInfoJsonData)
  addInputInsrdInfo.insrdSex = 'M' // 피보험자성별  M:남자/F:여자
  addInputInsrdInfo.aisdSex = 'M' // 종피보험자성별 M:남자/F:여자
  addInputInsrdInfo.ubent = 'N' // 태아여부 Y:태아/N:태아아님
 
  // 피보험자가 태아일때
  if (signerData.unchYn === 'Y') {
    addInputInsrdInfo.ubent = 'Y' // 태아여부 Y:태아/N:태아아님
  } // end if
 
  // 피보험자_계약자와의관계 (ASR210600884_20210720_PJO)
  if (eltrnSaveData.insrdContrRltnScCd !== undefined && eltrnSaveData.insrdContrRltnScCd !== '' && eltrnSaveData.insrdContrRltnScCd !== null) {
    let tmp = ''
    switch (eltrnSaveData.insrdContrRltnScCd) {
      case '01':
        tmp = '본인'
        break
      case '02':
        tmp = '배우자'
        break
      case '03':
        tmp = '자녀'
        break
      case '04':
        tmp = '부모'
        break
      case '05':
        tmp = '형제자매'
        break
      case '06':
        tmp = '조부모'
        break
      case '07':
        tmp = '외조부모'
        break
      case '08':
        tmp = '손자(녀)'
        break
      case '09':
        tmp = '시부모'
        break
      case '10':
        tmp = '장인장모'
        break
      case '11':
        tmp = '며느리, 사위'
        break
      default:
        break
    }
    addInputInsrdInfo.relation1 = tmp // relation1 / 텍스트 값 (   Ex: 배우자, 자녀)
  }

  return addInputInsrdInfo
}
/************************************************************************************************
 * Function명 : addFATCAInfoDocForm
 * 설명       : FATCA 정보 세팅
 * @param eltrnSaveData
 * @param fatcaData
 ************************************************************************************************/
const addFATCAInfoDocForm = function (eltrnSaveData, fatcaData) {
  let addFATCAInfo = Object.assign({}, addInputBaseFATCAInfoJsonData)
  if (eltrnSaveData.fatcaCnfmObjYn === 'Y') {
    // addFATCAInfo.oversPtaxYn = eltrnSaveData.fatcaCnfmObjYn // 해외거주자/납세의무자여부 (Y/N)

    // FATCA 정보
    if (fatcaData.fatcaCustCnfMatrCd !== '1') { // 해당 사항없음 1
      addFATCAInfo.usaDwlrYn = 'Y' // 미국거주자 여부 (Y/N)
    } else {
      addFATCAInfo.usaDwlrYn = 'N' // 미국거주자 여부 (Y/N)
    } // end else if

    // 미국거주자 구분 (미국시민권자 : 2 / 미국영주권자 : 3 / 미국세법상미국거주자 : 4)
    if (fatcaData.fatcaCustCnfMatrCd === '2') {
      addFATCAInfo.usaDwlrSc = '01' // 미국시민권자
    } else if (fatcaData.fatcaCustCnfMatrCd === '3') {
      addFATCAInfo.usaDwlrSc = '02' // 미국영주권자
    } else if (fatcaData.fatcaCustCnfMatrCd === '4') {
      addFATCAInfo.usaDwlrSc = '03' // 미국세법상미국거주자
    } // end else if

    // 미국시민권자
    addFATCAInfo.etcCntryDwlrYn = fatcaData.koreaUsaExcluOversDwYn // 한/미외 해외거주자 (Y/N)
    addFATCAInfo.engNm01 = fatcaData.fatcaEngNm01 // 영문성명 (성)
    addFATCAInfo.engNm02 = fatcaData.fatcaEngNm02 // 영문성명 (성)
    addFATCAInfo.engAddr = fatcaData.fatcaEngAddr // 영문주소
    addFATCAInfo.taxsPpsDmclCntry1 = fatcaData.taxsPpsDmclCntryCd01 // 조세목적상 거주지 국가 1
    addFATCAInfo.ptaxNo1 = fatcaData.taxprNo01 // 납세자번호 1
    addFATCAInfo.ptaxNoUnInptRsn1 = fatcaData.taxprNoUnSbmtRsnCd01 // 납세자번호 미취득 사유 코드 1

    addFATCAInfo.taxsPpsDmclCntry2 = fatcaData.taxsPpsDmclCntryCd02 // 조세목적상 거주지 국가 2
    addFATCAInfo.ptaxNo2 = fatcaData.taxprNo02 // 납세자번호 2
    addFATCAInfo.ptaxNoUnInptRsn2 = fatcaData.taxprNoUnSbmtRsnCd02 // 납세자번호 미취득 사유 코드 2

    addFATCAInfo.taxsPpsDmclCntry3 = fatcaData.taxsPpsDmclCntryCd03 // 조세목적상 거주지 국가 3
    addFATCAInfo.ptaxNo3 = fatcaData.taxprNo03 // 납세자번호 3
    addFATCAInfo.ptaxNoUnInptRsn3 = fatcaData.taxprNoUnSbmtRsnCd03 // 납세자번호 미취득 사유 코드 3

    addFATCAInfo.unAcqRsn = fatcaData.fatcaSbmtDnlRsn // 미취득 사유
  } // end else if
  return addFATCAInfo
}
/************************************************************************************************
 * Function명 : addContBankInfoDocForm
 * 설명       : 계약자 은행정보
 * @param signerData
 * @param bankData
 * @param contInfoData
 * @parma cardData
 ************************************************************************************************/
const addContBankInfoDocForm = function (eltrnSaveData, bankData, contInfoData, cardData) {
  let addContBankInfo = Object.assign({}, addInputBankInfoJsonData)
  console.log('>>>>PSDocFormUtil.초회보험료납입방법코드:' + eltrnSaveData.frtmPrmPadMthCd)
  console.log('>>>>PSDocFormUtil.cardData:' + JSON.stringify(cardData))
  // 후입금 여부에 따라 계좌 정보 조회
  addContBankInfo.aftRctmHopYn = eltrnSaveData.aftRctmHopYn
  if (eltrnSaveData.aftRctmHopYn === 'N') {
  /*
    let cmcustAccnInfoListVO = bankData.cmcustAccnInfoListVO
    for (let i = 0; i < cmcustAccnInfoListVO.length; i++) {
      let cmcustAccnInfo = cmcustAccnInfoListVO[i]
      if (cmcustAccnInfo.accnId === eltrnSaveData.bnkAccnId) {
        addContBankInfo.bnkNm = cmcustAccnInfo.bnkCdNm // 금융기관명
        addContBankInfo.bnkAccnno = cmcustAccnInfo.accnNo // 계좌번호
        addContBankInfo.dpstrnm = cmcustAccnInfo.dpstrNm // 예금주명
      } // end if
    } // end for i
    addContBankInfo.dpstrDob = PSDateUtil.getFullBirthDate(bankData.rrn) // 예금주생년월일 YYYYMMDD
    */
    // (ASR240400333_24.05 장한나)
    if (eltrnSaveData.frtmPrmPadMthCd === '9' && cardData !== null && cardData !== undefined !== '') { // 초회보험료납입방법코드 :  9 신용카드
      cardData.cardNum // 카드 번호 /"cardNum":"1234123412341234"
      cardData.cardAvlYm // 카드유효년월 / "202506"
      let tmp = ''
      if( cardData.cardAvlYm  !== '' || cardData.cardAvlYm !== undefined ||cardData.cardAvlYm.length === 6 ) {
        tmp =  cardData.cardAvlYm.substring(4,6) + '/' + cardData.cardAvlYm.substring(2,4) 
      }
      addContBankInfo.bnkNm = cardData.cardTypeNm // 카드회사명 / cardTypeNm":"현대카드"
      addContBankInfo.bnkAccnno = cardData.cardNum +'('+ tmp + ')'  // ( 1234567812345678 (05/25) )
      addContBankInfo.dpstrnm =  contInfoData.custNm // 계약자 (계예 동일인경우만 카드납방가능)
      addContBankInfo.dpstrDob = PSDateUtil.getFullBirthDate(contInfoData.knb) // 예금주생년월일 YYYYMMDD

    } else {
      let cmcustAccnInfoListVO = bankData.cmcustAccnInfoListVO
      for (let i = 0; i < cmcustAccnInfoListVO.length; i++) {
        let cmcustAccnInfo = cmcustAccnInfoListVO[i]
        if (cmcustAccnInfo.accnId === eltrnSaveData.bnkAccnId) {
          addContBankInfo.bnkNm = cmcustAccnInfo.bnkCdNm // 금융기관명
          addContBankInfo.bnkAccnno = cmcustAccnInfo.accnNo // 계좌번호
          addContBankInfo.dpstrnm = cmcustAccnInfo.dpstrNm // 예금주명
        } // end if
      } // end for i
      addContBankInfo.dpstrDob = PSDateUtil.getFullBirthDate(bankData.rrn) // 예금주생년월일 YYYYMMDD
    }
    addContBankInfo.rtpMth = eltrnSaveData.frtmPrmPadMthCd // 제1회보험료실시간출금이체신청여부 (1 실시간 / 4가상계좌)
    addContBankInfo.atrAplYn = eltrnSaveData.prmAtrYn // 제2회이후보험료자동이체및실시간출금이체신청여부
    addContBankInfo.trsfObjCd = eltrnSaveData.trsfObjCd // 이체대상코드 (01:보험료,  02:보험료+이자)
    addContBankInfo.trsfHopddCd = eltrnSaveData.trsfHopDdCd // 이체희망일코드 (05:5일, 10:10일, 15:15일, 20:20일, 25:25일)
    addContBankInfo.atRmtnApl = eltrnSaveData.atRmtnAplYn === 'Y' ? 'Y' : 'N' // 분할-배당-연금/휴면 보험금 자동송금 신청여부 (Y/N)

    console.log('>>>>PSDocFormUtil.addContBankInfo:' + JSON.stringify (addContBankInfo))
  } else {
    addContBankInfo.dpstrnm = contInfoData.custNm // 예금주명
    addContBankInfo.dpstrDob = PSDateUtil.getFullBirthDate(contInfoData.knb) // 예금주생년월일 YYYYMMDD
  }// end else if
  return addContBankInfo
}
/************************************************************************************************
 * Function명 : signerDetailDocForm
 * 설명       : 사인대상 정보 및 완료 여부 체크
 * @param signerData
 * @param hoshInfoList
 ************************************************************************************************/
const signerDetailDocForm = function (signerData, hoshInfoList, eltrnSaveData, pvmnotice) {
  let signerDetailList = []
  let prtrAuthorization = true
  let contrSignerItem = Object.assign({}, signerDetailJsonData)
  let insrdSignerItem = Object.assign({}, signerDetailJsonData)
  // 계약자 (필수)
  contrSignerItem.signerTitle = '계약자' // 계약자 , 피보험자, Fc등 화면에 표시될 네이밍(실제 홍길등으로 표기도 가능)
  contrSignerItem.signerCode = '1' // 계약자 : 1, 피보험자 : 2, 종피보험자 : 3, 친권자 : 4, FC : 9
  if (signerData.contrElstYn === 'Y') {
    contrSignerItem.authorization = false // 인증정보 ==> 이번에 사인을 실행 할지 판단 근거
  } else {
    contrSignerItem.authorization = signerData.contrAthntMethCd.trim() !== '' // 인증정보 ==> 이번에 사인을 실행 할지 판단 근거
  } // end else if
  contrSignerItem.complete = signerData.contrElstYn === 'Y' // 서명 완료 정보
  // 계약자=예금주 여부 설정
  if (eltrnSaveData.contrDpstrSameYn === 'Y') {
    // 계약자-예금주동일 = Y
    contrSignerItem.isAccountHolder = true
  } else {
    contrSignerItem.isAccountHolder = false
  }
  signerDetailList.push(contrSignerItem)

  // 계피상이 피보험자, 종피, 친권자 (선택)
  if (signerData.contrInsrdSameYn === 'N') {
    // 계피 상이 일때 피보험자 (필수)

    insrdSignerItem.signerTitle = '피보험자' // 계약자 , 피보험자, Fc등 화면에 표시될 네이밍(실제 홍길등으로 표기도 가능)
    insrdSignerItem.signerCode = '2' // 계약자 : 1, 피보험자 : 2, 종피보험자 : 3, 친권자 : 4, FC : 9
    // 미성년자 일때 피보험자 인증 완료로 처리
    if (signerData.insrdElstYn === 'Y') {
      insrdSignerItem.authorization = false // 인증정보 ==> 이번에 사인을 실행 할지 판단 근거
    } else {
      if (signerData.mnisdMiorYn === 'X' || signerData.mnisdMiorYn === 'Y') {
        insrdSignerItem.authorization = true // 인증정보 ==> 이번에 사인을 실행 할지 판단 근거
      } else {
        insrdSignerItem.authorization = signerData.insrdAthntMethCd.trim() !== '' // 인증정보 ==> 이번에 사인을 실행 할지 판단 근거
      } // end else if
    } // end else if
    insrdSignerItem.complete = signerData.insrdElstYn === 'Y' // 서명 완료 정보
    // 피보험자=예금주 여부 설정
    if (eltrnSaveData.insrdDpstrSameYn === 'Y') {
      // 피보험자-예금주동일 = Y
      insrdSignerItem.isAccountHolder = true
    } else {
      insrdSignerItem.isAccountHolder = false
    }
    signerDetailList.push(insrdSignerItem)

    // 종피 보험자 추가
    let aisdInfo = hoshInfoList.filter(item => item.contvInsrdCd === '23')
    if (aisdInfo && aisdInfo.length > 0) {
      let aisdSignerItem = Object.assign({}, signerDetailJsonData)
      aisdSignerItem.signerTitle = '종피보험자' // 계약자 , 피보험자, Fc등 화면에 표시될 네이밍(실제 홍길등으로 표기도 가능)
      aisdSignerItem.signerCode = '3' // 계약자 : 1, 피보험자 : 2, 종피보험자 : 3, 친권자 : 4, FC : 9
      if (signerData.aisdElstYn === 'Y') {
        aisdSignerItem.authorization = false // 인증정보 ==> 이번에 사인을 실행 할지 판단 근거
      } else {
        aisdSignerItem.authorization = signerData.contrAthntMethCd.trim() !== '' // 인증정보 ==> 이번에 사인을 실행 할지 판단 근거
      } // end else if
      aisdSignerItem.complete = signerData.aisdElstYn === 'Y' // 서명 완료 정보
      signerDetailList.push(aisdSignerItem)
    } // end if

    // 미성년자 일때 친권자 사인정보 추가
    if (signerData.mnisdMiorYn === 'X' || signerData.mnisdMiorYn === 'Y') {
      let prtrPesonSignerItem = Object.assign({}, signerDetailJsonData)
      prtrPesonSignerItem.signerTitle = '친권자' // 계약자 , 피보험자, Fc등 화면에 표시될 네이밍(실제 홍길등으로 표기도 가능)
      prtrPesonSignerItem.signerCode = '4' // 계약자 : 1, 피보험자 : 2, 종피보험자 : 3, 친권자 : 4, FC : 9
      if (signerData.prtrPesonElstYn === 'Y') {
        prtrPesonSignerItem.authorization = false // 인증정보 ==> 이번에 사인을 실행 할지 판단 근거
      } else {
        prtrPesonSignerItem.authorization = true // 인증정보 ==> 이번에 사인을 실행 할지 판단 근거
      } // end else if
      prtrPesonSignerItem.complete = signerData.prtrPesonElstYn === 'Y' // 서명 완료 정보
      signerDetailList.push(prtrPesonSignerItem)
    } // end if
    
  } // end if

  // 200109 예금주
  // 1) 예금주 인증여부 판단
  let dpstrPrtrAuthorization = false
  if (contrSignerItem.authorization && signerData.contrDpstrSameYn === 'Y') {
    // 계약자서명O, 계약자예금주동일O
    dpstrPrtrAuthorization = true
  } else if (signerData.contrInsrdSameYn === 'N' && insrdSignerItem.authorization && signerData.insrdDpstrSameYn === 'Y') {
    // 계피상이O, 피보험자서명O, 피보험자예금주동일O
    dpstrPrtrAuthorization = true
  } else if (signerData.dpstrElstYn !== 'Y' && signerData.dpstrAthntMethCd.trim() !== '' && signerData.dpstrIdpnYn) {
    // 예금주전자서명X, 예금주본인인증O, 예금주단독O
    dpstrPrtrAuthorization = true
  }

  // 2) 예금주 정보 등록
  if (eltrnSaveData.dpstrIdpnYn === 'Y') {
    // 예금주가 단독 계약관계자인 경우
    let dpstrSignerItem = Object.assign({}, signerDetailJsonData)
    dpstrSignerItem.signerTitle = '예금주' // 계약자 , 피보험자, 예금주, Fc등 화면에 표시될 네이밍(실제 홍길등으로 표기도 가능)
    dpstrSignerItem.signerCode = '6' // 계약자 : 1, 피보험자 : 2, 종피보험자 : 3, 친권자 : 4, 예금주 : 6, FC : 9
    dpstrSignerItem.authorization = dpstrPrtrAuthorization // 인증정보 ==> 이번에 사인을 실행 할지 판단 근거
    dpstrSignerItem.complete = signerData.dpstrElstYn === 'Y' // 서명 완료 정보
    dpstrSignerItem.isAccountHolder = true
    signerDetailList.push(dpstrSignerItem)
  }

  // FC서명여부 확인
  prtrAuthorization = true
  if (signerData.contrAthntMethCd.trim() === '') {
    // 계약자_본인인증X
    prtrAuthorization = false // FC 사인대상 여부 N
  } else if (signerData.contrInsrdSameYn === 'N' && signerData.insrdAthntMethCd.trim() === '' ) {
    // 피보험자O, 피보험자_본인인증X
    prtrAuthorization = false // FC 사인대상 여부 N
  } else if (eltrnSaveData.dpstrIdpnYn === 'Y' && signerData.dpstrAthntMethCd.trim() === '' ) {
    // 예금주O, 예금주_본인인증X
    prtrAuthorization = false // FC 사인대상 여부 N
  }

  // FC 정보(필수)
  let fcSignerItem = Object.assign({}, signerDetailJsonData)
  fcSignerItem.signerTitle = 'FC' // 계약자 , 피보험자, Fc등 화면에 표시될 네이밍(실제 홍길등으로 표기도 가능)
  fcSignerItem.signerCode = '9' // 계약자 : 1, 피보험자 : 2, 종피보험자 : 3, 친권자 : 4, FC : 9
  fcSignerItem.authorization = prtrAuthorization // 인증정보 ==> 이번에 사인을 실행 할지 판단 근거
  fcSignerItem.complete = signerData.fcElstYn === 'Y' // 서명 완료 정보
  signerDetailList.push(fcSignerItem)

  // 보험거래문서디지털화 : pvmnotic(청약) 추가
  if (pvmnotice['signerDetail'] && pvmnotice['signerDetail'].length > 0) {
    pvmnotice['signerDetail'].forEach((el, i) => {
      signerDetailList.forEach((elj, j) => {
        if (el.signerCode === elj.signerCode) {
          elj.pvmnotice = JSON.parse(JSON.stringify(el.pvmnotice))
        }
      })
    })
  }
  return signerDetailList
}

/************************************************************************************************
 * Function명 : pvmnoticeDocForm
 * 설명       : 고지 정보 세팅
 * @param pvmnotice
 ************************************************************************************************/
const pvmnoticeDocForm = function (pvmnotice) {
  return pvmnotice['pvmnotice']
}

/************************************************************************************************
 * Function명 : JsonInputDataDocForm
 * 설명       : 발행 JsonInputData 세팅
 * @param jsonInputData
 ************************************************************************************************/
const JsonInputDataDocForm = function (jsonInputData) {
  /* 예시
  {'servlet_addr': window.vue.config.http.baseURI + '/OZRepeaterServlet',
  'FORM_ARY': [{'formId': '/PQ/npipFG109801'}, {'formId': '/PQ/npipFG109901'}, {'formId': '/PQ/npipFG106301'}],
  'DATA_ARY': [{'OFR_INFO_SNGL': {'zaCurPrtPageFgr': '1'}}, {'ENT_COND_ARY': [{'zaContCstuTypCd': '11'}]}, {'OFR_INFO_SNGL': {'zaGNm': '2', 'zaReprGrno': '3'}}],
  'META_ARY': [{'formname': 'npipFG109801', 'color': 'C', 'duplex': '1', 'watermark': '1'}, {'formname': 'npipFG109901', 'color': 'C', 'duplex': '1', 'watermark': '1'}, {'formname': 'npipFG106301', 'color': 'C', 'duplex': '1', 'watermark': '1'}],
  'PBL_DFNT': {'fcname': '팡미추', 'fccode': '0001057465', 'merge': 'Y'}
  } */

  let JsonInputData = jsonInputData
  JsonInputData.servlet_addr = DocForm.getOzSevletAddr()
  return JsonInputData
}
/************************************************************************************************
 * Function명 : parsingJSONDocForm
 * 설명       : OZ 전자서명 입력 JSON 정보 추출
 * @param complets
 * @param strJsonData
 * @param vuchNo 영수증번호
 ************************************************************************************************/
const parsingJSONDocForm = function (complets, strJsonData) {
  let reJsonData = {
    data: {
      bnfrAsgnCnsntYn: '0', // 수익자지정동의여부 (계) 1/0
      ralOwnerYn: 'Y', // 실제소유자여부 (계)
      cmmuRscCnsntYn: '0', // 통신해지가능여부 (계) 1/0
      asgnProxyClamEntYn: 'N', // 지정대리청구가입여부 (계)
      rprtRcpplScCd: '3', // 보고서수령방법코드 (계)
      contDsclsYn: 'N', // 계약고지여부 (피)
      pCrdinPrcusCnsnt: 'Y', // 개인신용정보활용동의여부
      insrdHght: '0', // 피보험자신장
      insrdBwght: '0', // 피보험자체중
      insrdSmkYn: 'N', // 피보험자흡연여부
      aisdHght: '0', // 종피보험자신장
      aisdBwght: '0', // 종피버험자체중
      aisdSmkYn: 'N', // 종피보험자흡연여부
      prgwkFgr: '0', // 임신주수 (종피)
      cylClbyDignYn: 'N', // 주기별진단여부 (3/2/5체크) 2/28 반영 추가
      diabtNobWrtYn: 'Y', // 당뇨특약알릴의무작성여부
      diabtDignYn: 'X', // 당뇨진단여부 2/28 반영 추가
      duCnt: 0, // 알릴의무사항 2개 이상 시 접수 불가 처리
      docNo: '4294966589', // 처리되는 문서 번호
      snbkGudnSmsReciYn: 'N', // 출금동의서 반송안내 수신여부
      txbkScCd: ' ', // 세금우대구분코드(TXBK_SC_CD)
      asgnPrsonIapAplYn: ' ', // 지정인알림신청여부(2022.03 ASR220300097 김문준프로요청)
      pvmnotice: [], // 알릴고지 서명앱 입력정보
      amlWrtDataList : [] // AML 서식 입력 정보리스트
    },
    trtRslt: 1, // 로직 처리 오류가 있는지 확인 정상 1 비정상 0
    trtRsltMsg: '' // 로직 처리 오류가 있는지 확인 정상 1 비정상 0
  }
  try {
    let duCnt1 = 0
    let duCnt2 = 0
    let duCnt3 = 0
    let duCnt4 = 0
    let duCnt5 = 0
    let duCnt6 = 0
    let duCnt7 = 0
    let duCnt8 = 0
    let duCnt9 = 0
    let duCnt10 = 0
    let duCnt11 = 0
    let duCnt12 = 0
    let duCnt13 = 0
    let duCnt14 = 0
    let duCnt15 = 0 // [ASR220200903] 간편치아 신상품 판매(3/15 예정)에 따른 신규고지서(pvm_noticeKDT) - 추가
    let duCnt20 = 0 // 건강고지형 고지서 2종 ASR250101054 20250306(민휘림)
    let duCnt21 = 0 // 건강고지형 고지서 2종 ASR250101054 20250306(민휘림)
    let tempAmlFormList = [] // AML서식관련 데이터 리스트


    if (complets === true) {
      console.log('parsingJSONDocForm:::start::')
      strJsonData.forEach((el, i) => {
        if (el.formId === 'pnbau1' && el['Input']) {
          // 수익자지정동의여부(계)1/0 : pnbau1, G05 = Y 일때 수익자지정동의여부 동의함 / N일때 수익자 지정동의여부 동의안함
          if (el['Input']['G05'] && el['Input']['G05'] === 'Y') {
            reJsonData.data.bnfrAsgnCnsntYn = '1'
          }
          // 지정인알림신청여부(2022.03 ASR220300097 김문준프로요청)값 없으면 빈값하고싶다하니..
          console.log('지정인알림신청여부:' + el['Input']['G15'])
          if (el['Input']['G15'] && el['Input']['G15'] === 'N') {
            reJsonData.data.asgnPrsonIapAplYn = 'N'
          } else if (el['Input']['G15'] && el['Input']['G15'] === 'Y') {
            reJsonData.data.asgnPrsonIapAplYn = 'Y'
          }
          // 실제소유자여부(계): pnbau1, G04=Y일때 실제소유자 Y / N일때 현장접수 불가
          if (el['Input']['G04'] && el['Input']['G04'] === 'N') {
            reJsonData.data.ralOwnerYn = 'N'
          }
          // 통신해지가능여부(계)1/0 : pnbau1, cancleYN =Y일때  통신해지 Y / N일때 통신해지 N
          if (el['Input']['cancleYN'] && el['Input']['cancleYN'] === 'Y') {
            reJsonData.data.cmmuRscCnsntYn = '1'
          }
          // 지정대리청구가입여부(계) : pnbau1, G06 = Y일때 현장접수 불가
          // if (el['Input']['G06'] && el['Input']['G06'] === 'Y') {
          //   reJsonData.data.asgnProxyClamEntYn = 'Y'
          // }
          // 출금동의서반송안내수신여부
          if (el['Input']['G02'] && el['Input']['G02'] === 'Y') {
            reJsonData.data.snbkGudnSmsReciYn = 'Y'
          }
          // 세금우대구분코드(TXBK_SC_CD)
          if (el['Input']['G100']) {
            let G100Text = el['Input']['G100'].trim()
            reJsonData.data.txbkScCd = ''
            switch (G100Text) {
              case 'R1':
                reJsonData.data.txbkScCd = '8'
                break
              case 'R2':
                reJsonData.data.txbkScCd = '0'
                break
              case 'R3':
                reJsonData.data.txbkScCd = '1'
                break
              case 'R4':
                reJsonData.data.txbkScCd = '4'
                break
              case 'R5':
                reJsonData.data.txbkScCd = '2'
                break
              case 'R6':
                reJsonData.data.txbkScCd = '3'
                break
              case 'R7':
                reJsonData.data.txbkScCd = '5'
                break
              case 'R8':
                reJsonData.data.txbkScCd = '6'
                break
              default:
                break
            }
          }
        }
        console.log('el.formId:' + el.formId)
        // pvm_notice* 형태의 양식
        if (String(el.formId).startsWith('pvm_notice')) {
          // 피보험자신장
          if (el['Input']['OZ1_G980']) {
            reJsonData.data.insrdHght = stringToint(el['Input']['OZ1_G980'])
          }
          // 피보험자체중
          if (el['Input']['OZ1_G981']) {
            reJsonData.data.insrdBwght = stringToint(el['Input']['OZ1_G981'])
          }
          // 피보험자흡연여부
          if (el.formId && ('pvm_noticeWELL7;pvm_noticeWELL10;').indexOf(el.formId) > -1) {
            if (el['Input']['OZ1_G11_YN'] && el['Input']['OZ1_G11_YN'] === 'Y') {
              reJsonData.data.insrdSmkYn = 'Y'
            }
          } else {
            if (el['Input']['OZ1_G10_YN'] && el['Input']['OZ1_G10_YN'] === 'Y') {
              reJsonData.data.insrdSmkYn = 'Y'
            }
          }

          // 종피보험자신장
          if (el['Input']['OZ2_G980']) {
            reJsonData.data.aisdHght = stringToint(el['Input']['OZ2_G980'])
          }
          // 종피보험자체중
          if (el['Input']['OZ2_G981']) {
            reJsonData.data.aisdBwght = stringToint(el['Input']['OZ2_G981'])
          }
          // 종피보험자흡연여부
          if (el.formId && ('pvm_noticeWELL7;pvm_noticeWELL10;').indexOf(el.formId) > -1) {
            if (el['Input']['OZ2_G11_YN'] && el['Input']['OZ2_G11_YN'] === 'Y') {
              reJsonData.data.aisdSmkYn = 'Y'
            }
          } else {
            if (el['Input']['OZ2_G10_YN'] && el['Input']['OZ2_G10_YN'] === 'Y') {
              reJsonData.data.aisdSmkYn = 'Y'
            }
          }
          // 임신주수(종피)
          if (el['Input']['OZ2_G982']) {
            reJsonData.data.prgwkFgr = stringToint(el['Input']['OZ2_G982'])
          }
        }
        // 주기별진단여부(3/2/5체크)2/28반영추가
        /*
          pvm_noticeENA(간편실손) : G01=Y or G02=Y or G03=Y or 4번(직업문항) 부업/겸업/위험취미에 데이터가 하나라도 입력된 경우일때 주기별진단여부 =Y
          pvm_noticeENT2(간편건강) : G01=Y or G02=Y or G03=Y or 4번(직업문항) 부업/겸업/위험취미에 데이터가 하나라도 입력된 경우일때 주기별진단여부 =Y
                                  or G02_1=Y 조건 추가 // ASR230800592_경증간편 고지서 신규문항 알릴고지 Y(2023.08.17)
          pvm_noticeKCM(간편종합간병) : G01=Y or G02=Y or G03=Y or 4번(직업문항) 부업/겸업/위험취미에 데이터가 하나라도 입력된 경우일때 주기별진단여부 =Y
          pvm_noticeQP(신속패스) : G01=Y or G02=Y or G03=Y or 4번(직업문항) 부업/겸업/위험취미에 데이터가 하나라도 입력된 경우 주기별진단여부 = Y

          pvm_noticeNENT10,pvm_noticeNENT5,pvm_noticeNENT2,pvm_noticeNENT1(신간편 4종)
          :G01=Y or G02=Y or G03=Y 데이터가 하나라도 입력된 경우일때 주기별진단여부(피) =Y // 알릴의무_신간편 4종 - 계약고지여부, 주기별진단여부 (pvm_noticeENA 동일조건)  (ASR240900122_이현수_241008 반영)
        */
         // ASR240700941 [전자서명] ※ 계약고지여부, 주기별진단여부 로직 변경  ( 이현수 / 240905)
         // 4번(직업문항) 부업/겸업/위험취미(OZ1_G983/OZ1_G984/OZ1_G985, 종피는 2) 데이터유무로 Y/N판단하는 로직 제거
        if (reJsonData.data.cylClbyDignYn === 'N' &&
            (el.formId === 'pvm_noticeENA' || el.formId === 'pvm_noticeENT2' || el.formId === 'pvm_noticeKCM' || el.formId === 'pvm_noticeQP'|| 
             el.formId === 'pvm_noticeNENT10' || el.formId === 'pvm_noticeNENT5' || el.formId === 'pvm_noticeNENT2' || el.formId === 'pvm_noticeNENT1'
            ) &&
            el['Input']) {
          // G(01~03) : OZ1_G(01~03)_YN
          for ( let j = 1; j <= 3; j++) {
            let attrNm = String(j)
            attrNm = 'OZ1_G' + lpad(attrNm, 2, '0') + '_YN' // ex) OZ1_G(01~03)_YN
            if (el['Input'][attrNm] && el['Input'][attrNm] === 'Y') {
              reJsonData.data.cylClbyDignYn = 'Y'
            }
          }

          // 20230817_ASR230800592_경증간편 고지서 신규문항 알릴고지 Y - G02_1 항목 추가 (김문준/이명진)
          let attrNmG021 = 'OZ1_G02_1_YN'          	
          if ( el.formId === 'pvm_noticeENT2' && el['Input'][attrNmG021] && el['Input'][attrNmG021] === 'Y') {
            reJsonData.data.cylClbyDignYn = 'Y'
          }

          // 부업/겸업/위험취미 : OZ1_G983/OZ1_G984/OZ1_G985 // 주기별진단여부 로직 변경 (ASR240700941_240905 PJO)
          // for ( let j = 983; j <= 985; j++) {
          //   let attrNm = 'OZ1_G' + String(j) // ex) OZ1_G983
          //   if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
          //     reJsonData.data.cylClbyDignYn = 'Y'
          //   }
          // }
        }
        /*
          pvm_noticeUS(초간편) : G01=Y or G02=Y 3번(직업문항) 부업/겸업/위험취미에 데이터가 하나라도 입력된 경우 주기별진단여부 = Y
        */
        if (reJsonData.data.cylClbyDignYn === 'N' && (el.formId === 'pvm_noticeUS') && el['Input']) {
          for ( let j = 1; j <= 2; j++) {
            let attrNm = String(j)
            attrNm = 'OZ1_G' + lpad(attrNm, 2, '0') + '_YN' // ex) OZ1_G(01~02)_YN
            if (el['Input'][attrNm] && el['Input'][attrNm] === 'Y') {
              reJsonData.data.cylClbyDignYn = 'Y'
            }
          }
          // 부업/겸업/위험취미 : OZ1_G983/OZ1_G984/OZ1_G985 // 주기별진단여부 로직 변경 (ASR240700941_240905 PJO)
          // for ( let j = 983; j <= 985; j++) {
          //   let attrNm = 'OZ1_G' + String(j) // ex) OZ1_G983
          //   if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
          //     reJsonData.data.cylClbyDignYn = 'Y'
          //   }
          // }
        }

        // 당뇨진단여부2/28반영추가 : pvm_noticeENT2이외 서식은 'X'값으로 고정
        if (reJsonData.data.diabtDignYn === 'X' && el.formId === 'pvm_noticeENT2' && el['Input']) {
          // G04=Y 일때 당뇨진단여부 =Y, G04 =N일때 당뇨진단여부 =N // 이외 data없을 경우 X
          if (el['Input']['OZ1_G04_YN'] && (el['Input']['OZ1_G04_YN'] === 'Y' || el['Input']['OZ1_G04_YN'] === 'N')) {
            reJsonData.data.diabtDignYn = el['Input']['OZ1_G04_YN']
          }
        }

        // 알릴의무사항2개이상시접수불가처리
        if (el.formId === 'pvm_noticePEN') { // pvm_noticePEN
          if (duCnt1 < 1) duCnt1++
        }
        if (el.formId === 'pvm_notice') { // pvm_notice
          if (duCnt2 < 1) duCnt2++
        }
        if (el.formId === 'pvm_noticeCI') { // pvm_noticeCI
          if (duCnt3 < 1) duCnt3++
        }
        if (el.formId === 'pvm_noticeENT') { // pvm_noticeENT
          if (duCnt4 < 1) duCnt4++
        }
        if (el.formId === 'pvm_noticeDT') { // pvm_noticeDT
          if (duCnt5 < 1) duCnt5++
        }
        if (el.formId === 'pvm_noticeENA') { // pvm_noticeENA
          reJsonData.data.docNo = el.formId
          if (duCnt6 < 1) duCnt6++
        }
        if (el.formId === 'pvm_noticeCN') { // pvm_noticeCN 1월 9일 누락 추가
          if (duCnt7 < 1) duCnt7++
        }
        if (el.formId === 'pvm_noticeENT2') { // pvm_noticeENT2 3월 4일 당뇨특약 추가
          reJsonData.data.docNo = el.formId
          if (duCnt8 < 1) duCnt8++
        }
        if (el.formId === 'pvm_noticeKCM') { // pvm_noticeKCM 4월 30일 신상품 추가
          reJsonData.data.docNo = el.formId
          if (duCnt9 < 1) duCnt9++
        }
        if (el.formId === 'pvm_noticeCM') { // pvm_noticeCM 4월 30일 신상품 추가
          reJsonData.data.docNo = el.formId
          if (duCnt10 < 1) duCnt10++
        }
        if (el.formId === 'pvm_noticeKID') { // pvm_noticeKID 5월 21일 알릴의무사항 추가
          reJsonData.data.docNo = el.formId
          if (duCnt11 < 1) duCnt11++
        }
        if (el.formId === 'pvm_noticeQP') { // pvm_noticeQP 2020.07.07 알릴의무사항-신속패스 추가
          reJsonData.data.docNo = el.formId
          if (duCnt12 < 1) duCnt12++
        }
        if (el.formId === 'pvm_noticeUS') { // pvm_noticeUS 2020.08.04 알릴의무사항-초간편
          reJsonData.data.docNo = el.formId
          if (duCnt13 < 1) duCnt13++
        }
        if (el.formId === 'pvm_noticeCRR') { // ASR210401294 알릴의무 전환/재가입노후실손
          reJsonData.data.docNo = el.formId
          if (duCnt14 < 1) duCnt14++
        }
        if (el.formId === 'pvm_noticeKDT') { // ASR220200903 2022.03.10 알릴의무사항-간편치아 추가
          reJsonData.data.docNo = el.formId
          if (duCnt15 < 1) duCnt15++
        }
        // pvm_noticeNENT10,pvm_noticeNENT5,pvm_noticeNENT2,pvm_noticeNENT1(신간편 4종) /(ASR240900122_이현수_241008 반영)
        let duCnt16,  duCnt17, duCnt18, duCnt19 = 0 
        if (el.formId === 'pvm_noticeNENT10') {
          reJsonData.data.docNo = el.formId
          if (duCnt16 < 1) duCnt16++
        }
        if (el.formId === 'pvm_noticeNENT5') {
          reJsonData.data.docNo = el.formId
          if (duCnt17 < 1) duCnt17++
        }
        if (el.formId === 'pvm_noticeNENT2') {
          reJsonData.data.docNo = el.formId
          if (duCnt18 < 1) duCnt18++
        }
        if (el.formId === 'pvm_noticeNENT1') {
          reJsonData.data.docNo = el.formId
          if (duCnt19 < 1) duCnt19++
        }
        // 건강고지형 고지서 2종 ASR250101054 20250306(민휘림)
        if (el.formId === 'pvm_noticeWELL7') {
          reJsonData.data.docNo = el.formId
          if (duCnt20 < 1) duCnt20++
        }
        if (el.formId === 'pvm_noticeWELL10') {
          reJsonData.data.docNo = el.formId
          if (duCnt21 < 1) duCnt21++
        }

        // 계약고지여부(피) : 알릴의무 고지서 별로 기준 상이
        if (reJsonData.data.contDsclsYn === 'N') {
          /*
             pvm_noticeCM(일반치매) : 상세고지 질병명란 or 6번(직업문항) 부업/겸업/위험취미에 데이터가 하나라도 입력된 경우 계약고지여부(피) = Y
             pvm_noticeDT(치아) : 상세고지 질병명란 or 4번(직업문항) 부업/겸업/위험취미에 데이터가 하나라도 입력된 경우 계약고지여부(피) = Y
             pvm_noticeCN(실버) : 상세고지 질병명란 or 12번(직업문항) 부업/겸업/위험취미에 데이터가하나라도 입력된 경우 계약고지여부(피) =Y
             [ASR220200903] 간편치아 신상품 판매(3/15 예정)에 따른 신규고지서(pvm_noticeKDT) - 추가(치아고지서와 동일)
             pvm_noticeKDT(간편치아) : 상세고지 질병명란 OR 4번(위험취미문항) 부업/겸업/위험취미 에 데이터가 하나라도 입력된 경우 알릴고지 의무 = Y
          */
          let _noticeForms = 'pvm_noticeCM(일반치매);pvm_noticeDT(치아);pvm_noticeCN(실버);pvm_noticeKDT(간편치아)'
          if (el.formId && (_noticeForms.indexOf(el.formId) > -1) && el['Input']) {
            // 질병명 : OZ3_D(01~04)_CD_NM, 질병코드 : OZ3_D(01~04)_CD
            for ( let j = 1; j <= 4; j++) {
              let attrNm = String(j)
              attrNm = 'OZ3_D' + lpad(attrNm, 2, '0') + '_CD_NM' // ex) OZ3_D(01~04)_CD_NM
              if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
                reJsonData.data.contDsclsYn = 'Y'
              }
            }
            // 부업/겸업/위험취미 : OZ1_G983/OZ1_G984/OZ1_G985 //계약고지여부  로직 변경 (ASR240700941_240905 PJO)
            // for ( let j = 983; j <= 985; j++) {
            //   let attrNm = 'OZ1_G' + String(j) // ex) OZ1_G983
            //   if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
            //     reJsonData.data.contDsclsYn = 'Y'
            //   }
            // }
          }
          /*
          ASR210400456_사랑On전자서명 _ 현장접수 로직변경_(PJO.20210416)_START
          pvm_noticeKID(어린이) : 상세고지 질병명란 or 12번(직업문항) 부업/겸업/위험취미에 데이터가 하나라도 입력된 경우 계약고지여부(피) = Y
          pvm_noticePEN(연금)   : 상세고지 질병명란 or 5번(직업문항)  부업/겸업/위험취미에 데이터가 하나라도 입력된 경우 계약고지여부(피) = Y
          *종피.보완 / 장유휘
          */
          _noticeForms = 'pvm_noticeKID(어린이);pvm_noticePEN(연금)'
          if (el.formId && (_noticeForms.indexOf(el.formId) > -1) && el['Input']) {
            console.log('_noticeForms>>:' + el.formId )
            for ( let j = 1; j <= 4; j++) {
              let attrNm = ''
              // (주피)질병명 : OZ3_D(01~04)_CD_NM, 질병코드 : OZ3_D(01~04)_CD
              attrNm = 'OZ3_D' + lpad(String(j), 2, '0') + '_CD_NM' // ex) OZ3_D(01~04)_CD_NM
              console.log('(주피)질병명.attrNm:' + attrNm + ':' + el['Input'][attrNm])
              if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
                reJsonData.data.contDsclsYn = 'Y'
              }
              // (종피)질병명: OZ4_D5(01~04)_CD_NM, 질병코드 : OZ4_D5(01~04)_CD
              attrNm = 'OZ4_D5' + lpad(String(j), 2, '0') + '_CD_NM' // ex) OZ4_D501_CD_NM
              console.log('(종피)질병명.attrNm:' + attrNm + ':' + el['Input'][attrNm])
              if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
                reJsonData.data.contDsclsYn = 'Y'
              }
            }
            // for ( let j = 983; j <= 985; j++) { //계약고지여부 로직 변경 (ASR240700941_240905 PJO)
            //   // (주피)부업/겸업/위험취미 : OZ1_G983/OZ1_G984/OZ1_G985)
            //   let attrNm = 'OZ1_G' + String(j) // ex) OZ1_G983
            //   console.log('(주피)부업/겸업/위험취미:' + attrNm + ':' + el['Input'][attrNm])
            //   if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
            //     reJsonData.data.contDsclsYn = 'Y'
            //   }
            //   // (종피)부업/겸업/위험취미 : (OZ2_G983,OZ2_G984, OZ2_G985)
            //   attrNm = 'OZ2_G' + String(j) // ex) OZ2_G983
            //   console.log('(종피)부업/겸업/위험취미:' + attrNm + ':' + el['Input'][attrNm])
            //   if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
            //     reJsonData.data.contDsclsYn = 'Y'
            //   }
            // }
            console.log('_DOCformUtil.contDsclsYn:' + reJsonData.data.contDsclsYn )
          }
          // ASR210400456_사랑On전자서명 _ 현장접수 로직변경_(PJO.20210416)_END
          /*
             pvm_noticeKCM(간편종합간병) : G01=Y or G02=Y or G03=Y or 4번(직업문항) 부업/겸업/위험취미에 데이터가 하나라도 입력된 경우일때 계약고지여부(피) =Y
             pvm_noticeENA(간편실손) : G01=Y or G02=Y or G03=Y or 4번(직업문항) 부업/겸업/위험취미에 데이터가 하나라도 입력된 경우일때 계약고지여부(피) =Y
             pvm_noticeENT2(간편건강) : G01=Y or G02=Y or G03=Y or 4번(직업문항) 부업/겸업/위험취미에 데이터가 하나라도 입력된 경우일때 계약고지여부(피) =Y
                                    or G02_1=Y 조건 추가 // ASR230800592_경증간편 고지서 신규문항 알릴고지 Y(2023.08.17)
             pvm_noticeQP(신속패스) : G01=Y or G02=Y or G03=Y or 4번(직업문항) 부업/겸업/위험취미에 데이터가 하나라도 입력된 경우 계약고지여부(피) = Y
             pvm_noticeNENT10,pvm_noticeNENT5,pvm_noticeNENT2,pvm_noticeNENT1(신간편 4종):G01=Y or G02=Y or G03=Y 데이터가 하나라도 입력된 경우일때 계약고지여부(피) =Y // 알릴의무_신간편 4종 - 계약고지여부, 주기별진단여부 (pvm_noticeENA 동일조건)  (ASR240900122_이현수_241008 반영)
          */
          _noticeForms = 'pvm_noticeKCM(간편종합간병);pvm_noticeENA(간편실손);pvm_noticeENT2(간편건강);pvm_noticeQP;pvm_noticeNENT10;pvm_noticeNENT5;pvm_noticeNENT2;pvm_noticeNENT1;'
          if (el.formId && (_noticeForms.indexOf(el.formId) > -1) && el['Input']) {
            // G(01~03) : OZ1_G(01~03)_YN
            for ( let j = 1; j <= 3; j++) {
              let attrNm = String(j)
              attrNm = 'OZ1_G' + lpad(attrNm, 2, '0') + '_YN' // ex) OZ1_G(01~03)_YN
              if (el['Input'][attrNm] && el['Input'][attrNm] === 'Y') {
                reJsonData.data.contDsclsYn = 'Y'
              }
            }

             // 20230817_ASR230800592_경증간편 고지서 신규문항 알릴고지 Y - G02_1 항목 추가 (김문준/이명진)
             let _noticeFormsG021 = 'pvm_noticeENT2(간편건강)'
             let attrNmG021 = 'OZ1_G02_1_YN'
             if ((_noticeFormsG021.indexOf(el.formId) > -1) && el['Input'][attrNmG021] && el['Input'][attrNmG021] === 'Y') {
               reJsonData.data.contDsclsYn = 'Y'
             }

            // 부업/겸업/위험취미 : OZ1_G983/OZ1_G984/OZ1_G985 //계약고지여부 (ASR240700941_240905 PJO)
            // for ( let j = 983; j <= 985; j++) {
            //   let attrNm = 'OZ1_G' + String(j) // ex) OZ1_G983
            //   if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
            //     reJsonData.data.contDsclsYn = 'Y'
            //   }
            // }
          }

          /*
             pvm_noticeUS(초간편) : G01=Y or G02=Y or 3번(직업문항) 부업/겸업/위험취미에 데이터가 하나라도 입력된 경우 계약고지여부(피) = Y
          */
          _noticeForms = 'pvm_noticeUS(초간편)'
          if (el.formId && (_noticeForms.indexOf(el.formId) > -1) && el['Input']) {
            // G(01~02) : OZ1_G(01~02)_YN
            for ( let j = 1; j <= 2; j++) {
              let attrNm = String(j)
              attrNm = 'OZ1_G' + lpad(attrNm, 2, '0') + '_YN' // ex) OZ1_G(01~02)_YN
              if (el['Input'][attrNm] && el['Input'][attrNm] === 'Y') {
                reJsonData.data.contDsclsYn = 'Y'
              }
            }
            // 부업/겸업/위험취미 : OZ1_G983/OZ1_G984/OZ1_G985 //계약고지여부  로직 변경 (ASR240700941_240905 PJO)
            // for ( let j = 983; j <= 985; j++) {
            //   let attrNm = 'OZ1_G' + String(j) // ex) OZ1_G983
            //   if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
            //     reJsonData.data.contDsclsYn = 'Y'
            //   }
            // }
          }

          /*
            pvm_notice(일반) : 상세고지 질병명란 or 7번(직업문항) 부업/겸업/위험취미에 데이터가 하나라도 입력된 경우 or 9번 해외체류 Y일 때 계약고지여부(피) = Y
         */
          if (el.formId === 'pvm_notice' && el['Input']) {
            console.log('_noticeForms>>>::' + el.formId )
            for ( let j = 1; j <= 4; j++) {
              let attrNm = ''
              // (주피)질병명 : OZ3_D(01~04)_CD_NM, 질병코드 : OZ3_D(01~04)_CD
              attrNm = 'OZ3_D' + lpad(String(j), 2, '0') + '_CD_NM' // ex) OZ3_D(01~04)_CD_NM
              if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
                reJsonData.data.contDsclsYn = 'Y'
              }
              // (종피)질병명 : OZ4_D5(01~04)_CD_NM, 질병코드 : OZ4_D5(01~04)_CD
              // ASR210400456_사랑On전자서명 _ 현장접수 로직변경_(PJO.20210416) : OZ4_D -> OZ4_D5
              attrNm = 'OZ4_D5' + lpad(String(j), 2, '0') + '_CD_NM' // ex) OZ4_D5(01~04)_CD_NM
              console.log('(종피)질병명.attrNm:' + attrNm + ':' + el['Input'][attrNm])
              if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
                reJsonData.data.contDsclsYn = 'Y'
              }
            }

            // for ( let j = 983; j <= 985; j++) { //계약고지여부  로직 변경 (ASR240700941_240905 PJO)
            //   // (주피)부업/겸업/위험취미 : OZ1_G983/OZ1_G984/OZ1_G985
            //   let attrNm = 'OZ1_G' + String(j) // ex) OZ1_G983
            //   if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
            //     reJsonData.data.contDsclsYn = 'Y'
            //   }
            //   // (종피)부업/겸업/위험취미 : OZ2_G983/OZ2_G984/OZ2_G985
            //   attrNm = 'OZ2_G' + String(j) // ex) OZ2_G983
            //   if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
            //     reJsonData.data.contDsclsYn = 'Y'
            //   }
            // }

            // 주피)9번 해외체류 OZ1_G09_YN
            let attrNm = 'OZ1_G09_YN'
            if (el['Input'][attrNm] && el['Input'][attrNm] === 'Y') {
              reJsonData.data.contDsclsYn = 'Y'
            }

            // 종피)9번 해외체류 OZ2_G509_YN
            attrNm = 'OZ2_G509_YN'
            if (el['Input'][attrNm] && el['Input'][attrNm] === 'Y') {
              reJsonData.data.contDsclsYn = 'Y'
            }
          }

          /*
            ASR210401294 전환/재가입노후실손 전용 고지서 제정
            해당항목 값 있을경우 알릴고지의무 Y
          */
          if (el.formId === 'pvm_noticeCRR' && el['Input']) {
            // OZ3_D01_CD_NM, OZ3_D02_CD_NM, OZ3_D03_CD_NM, OZ3_D04_CD_NM 항목체크
            for (let j = 1; j <= 4; j++) {
              let attrNm = ''
              attrNm = 'OZ3_D' + lpad(String(j), 2, '0') + '_CD_NM'
              if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
                reJsonData.data.contDsclsYn = 'Y'
              }
            }
            // OZ1_G983, OZ1_G984, OZ1_G985 항목체크 //계약고지여부  로직 변경 (ASR240700941_240905 PJO)
            // for (let j = 983; j <= 985; j++) {
            //   let attrNm = 'OZ1_G' + String(j)
            //   if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
            //     reJsonData.data.contDsclsYn = 'Y'
            //   }
            // }
          }

          // 21.06 min.ko ASR210600023 알릴고지Y_기준 변경 요청 (설금주 프로)
          if (el.formId === 'pvm_noticeextra' && el['Input']) {
            for ( let j = 5; j <= 20; j++) {
              let attrNm = ''
              // (주피)질병명 : OZ3_D(05~20)_CD_NM || 질병코드 : OZ3_D(05~20)_CD
              attrNm = 'OZ3_D' + lpad(String(j), 2, '0') + '_CD_NM' // ex) OZ3_D(05~20)_CD_NM
              if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
                reJsonData.data.contDsclsYn = 'Y'
              }
              // (종피)질병명 : OZ4_D5(05~20)_CD_NM || 질병코드 : OZ4_D5(05~20)_CD
              attrNm = 'OZ4_D5' + lpad(String(j), 2, '0') + '_CD_NM' // ex) OZ4_D5(05~20)_CD_NM
              if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
                reJsonData.data.contDsclsYn = 'Y'
              }
            }
          }
          /* 건강고지형 고지서 2종 ASR250101054 20250306(민휘림) */
          _noticeForms = 'pvm_noticeWELL7;pvm_noticeWELL10;'
          if (el.formId && (_noticeForms.indexOf(el.formId) > -1) && el['Input']) {
            // OZ3_D01_CD_NM ~ OZ3_D04_CD_NM 항목중 하나라도 데이터가 입력되어있으면 Y
            for (let j = 1; j <= 4; j++) {
              let attrNm = ''
              attrNm = 'OZ3_D' + lpad(String(j), 2, '0') + '_CD_NM'
              if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
                reJsonData.data.contDsclsYn = 'Y'
              }
            }
            // 10번 해외체류 OZ1_G10_YN에 데이터가 하나라도 입력된 경우일때 계약고지여부 =Y
            let attrNm = 'OZ1_G10_YN'
            if (el['Input'][attrNm] && el['Input'][attrNm] === 'Y') {
              reJsonData.data.contDsclsYn = 'Y'
            }

          }

          // 21.07 youngki78.lee ASR210600535 간편실손재가입 전용 계약전알릴의무사한 신설 (신승훈 프로) //계약고지여부  로직 변경 (ASR240700941_240905 PJO)
          // if (el.formId === 'pvm_noticeRENA' && el['Input']) {
          //   // 부업/겸업/위험취미 : OZ1_G983/OZ1_G984/OZ1_G985
          //   for ( let j = 983; j <= 985; j++) {
          //     let attrNm = 'OZ1_G' + String(j) // ex) OZ1_G983
          //     if (el['Input'][attrNm] && !isEmpty(el['Input'][attrNm])) {
          //       reJsonData.data.contDsclsYn = 'Y'
          //     }
          //   }
          // }
        }

        // 알릴고지 입력사항 전송
        if (el['Input']['formtype'] && (el['Input']['formtype'] === 'notice' || el['Input']['formtype'] === 'noticeextra')) {
          reJsonData.data.pvmnotice.push(JSON.parse(JSON.stringify(el)))
        }

        //AML관련 입력 FormData 수집
        if (el.formId && el['Input'] && PSConstants.FORM_NM_AML_LIST.indexOf(el.formId) > -1) {
          tempAmlFormList.push(JSON.parse(JSON.stringify(el)))
        }

      }) // END forEach
    }

    reJsonData.data.duCnt = duCnt1 + duCnt2 + duCnt3 + duCnt4 + duCnt5 + duCnt6 + duCnt7 + duCnt8 + duCnt9 + duCnt10 + duCnt11 + duCnt12 + duCnt13 + duCnt14 + duCnt15 + duCnt16 + duCnt17 + duCnt18 + duCnt19 + duCnt20 + duCnt21

    //===================== AML 서식 관련 처리 ========================

    if(tempAmlFormList && tempAmlFormList.length > 0){
      let rsltAmlData = refineAmlSaveData(tempAmlFormList) //AML 서식 데이터 정제 처리
      reJsonData.data.amlWrtDataList = rsltAmlData  
    }

  } catch (error) {
    console.log('parsingJSONDocForm::::error::' + error)
    reJsonData.trtRslt = 0
    reJsonData.trtRsltMsg = 'JSON 파싱 오류'
    return reJsonData
  } // end else

  return reJsonData
}

/************************************************************************************************
 * Function명 : refineAmlSaveData
 * 설명       : AML 서식 데이터 정제 처리
 ************************************************************************************************/
const refineAmlSaveData = function(tempAmlFormList){
  let amlCustContRltnTypCd = '' // AML고객계약관계유형코드 1 계약자 2 대리인(친권자) 3 수익자(사망) 서식ID로 구분처리 할것
  let formId = ''
  let len = (tempAmlFormList)?tempAmlFormList.length : 0
  let el = null
  let addItem = null  
  let rtnList = []

  for(let i = 0 ; i < len ; ++i){
    addItem = {}
    el = tempAmlFormList[i]
    formId = el.formId
    if(formId == PSConstants.FORM_NM_AML_CONTR || formId == PSConstants.FORM_NM_CORP_AML_CONTR){ // 개인 계약자용 pvm_custkycctr, 법인단체 계약자용 AML 서식 폼ID pvm_entkyc
      amlCustContRltnTypCd = '1'
    }else if(formId == PSConstants.FORM_NM_AML_PRTR01){  // 친권자용 AML 서식 폼ID pvm_custkycprx
      amlCustContRltnTypCd = '2'
    }else if(formId == PSConstants.FORM_NM_AML_DTH_BNFR01){ // 사망수익자용 AML 서식 폼ID pvm_custkycben
      amlCustContRltnTypCd = '3'
    }

    addItem = getAmlSaveFieldMatch(amlCustContRltnTypCd,el)
    if(addItem){
      addItem.vuchId = '' // 영수증번호는 처리계에서 셋팅되어 저장
      addItem.amlCustContRltnTypCd = amlCustContRltnTypCd // formId 구분으로 처리
      addItem.mobslFrmId = formId
      rtnList.push(addItem)
    }
    
  }//for

  return rtnList
}


/************************************************************************************************
 * Function명 : getAmlSaveFieldMatch
 * 설명       : AML 서식 입력데이터 매칭 값 셋팅
 ************************************************************************************************/
const getAmlSaveFieldMatch = function(amlCustContRltnTypCd,el){
  //AML 서식에서 찾을 입력 항목 매핑 필드명 정의 (개인 / 법인 포함)
  //테이블 NOT NULL 공백필요 주의
  let defFieldList = [
      {fieldNm : 'contEntRsnCd'     , default : ' '} //거래목적
    , {fieldNm : 'dlPpsEtcVal'      , default : ' '} //거래목적 기타입력값
    , {fieldNm : 'fdSrcScCd'        , default : ' '} //자금원천
    , {fieldNm : 'fdSrcEtcVal'      , default : ' '} //자금원천 기타입력값
    , {fieldNm : 'jobScCd'          , default : ' '} //직업정보
    , {fieldNm : 'mobslCustJobpNm'  , default : ' '} //직장명
    , {fieldNm : 'mobslDeptNm'      , default : ' '} //부서명
    , {fieldNm : 'mobslJobpsNm'     , default : ' '} //직위명
    , {fieldNm : 'mobslEcmpYmd'     , default : ' '} //입사일자
    , {fieldNm : 'mobslComyNm'      , default : ' '} //업체명
    , {fieldNm : 'mobslIndclNm'     , default : ' '} //업종
    , {fieldNm : 'mobslEstbYmd'     , default : ' '} //설립일자
    , {fieldNm : 'mobslBsnno'       , default : ' '} //사업자번호
    , {fieldNm : 'mobslYincmVal'    , default : ' '} //연소득
    , {fieldNm : 'asptnAsetScCd'    , default : ' '} //자산총액
    , {fieldNm : 'estbPpsCd'        , default : ' '} //설립목적
    , {fieldNm : 'estbEtcInptVal'   , default : ' '} //설립목적기타입력값
    , {fieldNm : 'entpOpnCd'        , default : ' '} //법인공개
    , {fieldNm : 'entpScleCd'       , default : ' '} //법인규모
    , {fieldNm : 'mobslLstckYn'     , default : ' '} //상장여부
    , {fieldNm : 'mobslLstckStxcNm' , default : ' '} //상장거래소명
  ]
  
  let rtnVal = {}
  let preFix = getPrefixAmlCustContRltn(amlCustContRltnTypCd)
  let defFieldNm = ''
  let preFixFieldNm = ''
  let i = 0
  let elInput = (el && el.Input) ? el.Input : null

  if(! elInput) return null

  for(i = 0 ; i < defFieldList.length ; ++i){
    defFieldNm         = defFieldList[i].fieldNm 
    preFixFieldNm      = preFix + defFieldNm 
    
    //필드명과 필드값에 매핑처리 값이 ''일때 고려 ' ' 셋팅되도록 비교문 추가
    if(elInput.hasOwnProperty(preFixFieldNm) && elInput[preFixFieldNm] && String(elInput[preFixFieldNm]).trim()){
      rtnVal[defFieldNm] = String(elInput[preFixFieldNm]).trim()      
    }else{
      rtnVal[defFieldNm] = defFieldList[i].default // 매핑없을때 기본값으로 셋팅
    }

  }//for i

  return rtnVal
}

/************************************************************************************************
 * Function명 : getPrefixAmlCustContRltn
 * 설명       : AML 계약관계자 코드 대응 Form 항목 Prefix 리턴 
 ************************************************************************************************/
 const getPrefixAmlCustContRltn = function(amlCustContRltnTypCd){
  let preFix = ''
  if(amlCustContRltnTypCd == '1'){ // 계약자용 AML 서식 항목 prefix
    preFix = 'aml1_'
  }else if(amlCustContRltnTypCd == '2'){  // 친권자용 AML 서식 항목 prefix
    preFix = 'aml4_'
  }else if(amlCustContRltnTypCd == '3'){ // 사망수익자용 AML 서식 항목 prefix
    preFix = 'aml8_'
  }
  return preFix
}

/************************************************************************************************
 * Function명 : stringToint
 * 설명       : 문자일때 0으로 입력
 ************************************************************************************************/
const stringToint = function (value) {
  if (!isNaN(parseInt(value))) {
    return value
  } // end if
  return '0'
}

/************************************************************************************************
* Function명 : lpad
* 설명 : 입력값 왼쪽에 값을 추가한다
************************************************************************************************/
const lpad = function (input, len, insertStr) {
  if (input !== null) {
    var result = input
    var tempLen = len - result.length
    for (var i = 0; i < tempLen; i++) {
      result = insertStr + result
    }
  }
  return result
}

/************************************************************************************************
* Function명 : isEmpty
* 설명 : 값이 비어 있는지 체크한다.
************************************************************************************************/
const isEmpty = function (val) {
  if (val === null || String(val).trim() === '' || val === undefined) {
    return true
  }
  return false
}


/************************************************************************************************
 * Function명 : setJsonData
 * 설명       : 전자서명 정보 JSON Data로 변경 처리
 * @param user_id           : 컨설턴트 ID
 * @param is_dev            : 개발계,검증계 / 운영계 여부
 * @param baseData          : 전자서명 필요한 기본 정보 (영수증번호/문서명/문서암호화정보)
 * @param eltrnSaveData     : 전자서명 계약자/피보험자 필수 입력 정보
 * @param contInfoData      : 계약자 상세 정보 = AML 계약자 기본정보
 * @param mnisdInfoData     : 피보험자 상세 정보
 * @param fatcaData         : 계약자 FATCA 정보
 * @param bankData          : 계약자 은행계좌 정보
 * @param signerData        : 전자서명 사인 정보
 * @param gafData           : 상품(가입)설계 심가 결과 정보
 * @param dpstrInfo         : 예금주정보
 * @param pvmnotice         : 고지정보
 * @param jsonInputData     : 발행 Json 전문
 * @param amlPrtrData01     : AML 친권자01 채널고객 기본정보
 * @param amlDthBnfrData01  : AML 사망수익자01 채널고객 기본정보
 * @param nundwYn           : 선심사여부 (선심사PJT(2024-01-29) 추가)
 * @param cardData          : 계약자 카드정보 / 신용카드 (ASR240400333_24.05 장한나)
 ************************************************************************************************/
DocForm.setJsonData = function (user_id, is_dev, baseData, eltrnSaveData, contInfoData, mnisdInfoData, fatcaData, bankData, signerData, gafData, dpstrInfo,
  pvmnotice, jsonInputData,amlPrtrData01,amlDthBnfrData01,nundwYn, cardData) {
  // 전자 서명 기본정보 세팅
  let baseJson = Object.assign({}, baseJsonData)
  baseJson.signLogYN = 'Y' // 로그파일 저장 여부
  baseJson.user_id = user_id // FC 사번, 재서명일때 고려 직접 로그인 정보 아이디 셋팅
  baseJson.preview = false // 미리보기 여부 (true:미리보기, false:전자서명)
  baseJson.xml_data = '' // 사랑on 에서 전달해주는 Xml Data(미리보기 발행 전문)
  baseJson.enc_state = true // 암호화 여부 (true:암호화, false:비암호화)
  baseJson.client_id = baseData.moDCEncrKeyVO.timestampId // 타임스탬프 ID - for TimeStamp
  baseJson.enc_key = baseData.moDCEncrKeyVO.encrKey // 암호화 키
  baseJson.pdf_name = baseData.tbtPbFilePath // PDF 저장 경로 및 파일이름
  baseJson.ozd_name = baseData.ozdFilePath // OZD 저장 경로 및 파일이름
  baseJson.pdf_doc_id = baseData.eltrnDoctId // PDF 문서 고유 ID - for TimeStamp
  baseJson.is_dev = is_dev // 타임스탬프 서버IP 구분(개발계/검증계=0, 운영계=1) - for TimeStamp
  baseJson.pdf_data = '' // 전자서명 정보 -for 중간저장
  // 전자 교부방법 정보 세팅
  baseJson.condition = conditionDocForm(signerData, eltrnSaveData, contInfoData)
  baseJson.add_input_data = []
  // 계약자 정보 세팅
  let isContrAthntMeth = signerData.contrAthntMethCd.trim() !== ''
  let addInputContInfo = contInfoDocForm(contInfoData, eltrnSaveData, fatcaData, bankData, isContrAthntMeth, dpstrInfo, cardData)


   //계약자 AML서식 기본정보 
   if(eltrnSaveData.amlContrPartnNo){
    Object.assign(addInputContInfo, addAmlInfoDocForm('1',contInfoData))
  }  

  //사망수익자 AML서식 기본정보
  if(eltrnSaveData.amlDthBnfrPartnNo && amlDthBnfrData01){
    Object.assign(addInputContInfo, addAmlInfoDocForm('3',amlDthBnfrData01))
  }  

  baseJson.add_input_data.push(addInputContInfo)

  // 계피 상이 일때 피보험자 정보 세팅
  if (signerData.contrInsrdSameYn === 'N') {
    let isInsrdAthntMeth = signerData.insrdAthntMethCd.trim() !== ''
    let addInputMnisdInfo = mnisdInfoDocForm(mnisdInfoData, signerData, eltrnSaveData, gafData.hoshInfoList, isInsrdAthntMeth, dpstrInfo)
    baseJson.add_input_data.push(addInputMnisdInfo)
    baseJson.signerInfo.isSameContractor = false
  } else {
    baseJson.signerInfo.isSameContractor = true
  } // end else if
  // 친권1정보가 존재하면 친권자정보 추가 (ASR210600884_2021.07.20_PJO)
  // 미성년자 일때 친권자 사인정보 추가
  // if (signerData.mnisdMiorYn === 'X' || signerData.mnisdMiorYn === 'Y') {
  if ( eltrnSaveData.prtrNm01 !== undefined && eltrnSaveData.prtrNm01 !== '' && eltrnSaveData.prtrNm01 !== null) {
    let addInputPrtr = prtrDocForm(eltrnSaveData, gafData.hoshInfoList)
    
    //친권자 AML 서식 기본정보
    if(eltrnSaveData.amlPrtrPesonPartnNo && amlPrtrData01){
      Object.assign(addInputPrtr, addAmlInfoDocForm('2',amlPrtrData01))
    }  
    
    baseJson.add_input_data.push(addInputPrtr)

  }

  // add_input_data필드에 선심사여부(NUNDW_YN) 필드 추가 (선심사PJT(2024-01-29))
  if (baseJson.add_input_data.length > 0) {
    for (let k = 0; k < baseJson.add_input_data.length; k++) {
      baseJson.add_input_data[k].NUNDW_YN = nundwYn
    }
  }
  
  baseJson.signerInfo.isBnfrChg = eltrnSaveData.bnfrChgCntnt
  baseJson.signerInfo.signerDetail = signerDetailDocForm(signerData, gafData.hoshInfoList, eltrnSaveData, pvmnotice)

  baseJson.pvmnotice = pvmnoticeDocForm(pvmnotice)
  baseJson.jsonInputData = JsonInputDataDocForm(jsonInputData)
  console.log('DocForm.setJsonData.condition:\n', JSON.stringify(baseJson.condition))
  console.log('DocForm.setJsonData.add_input_data:\n', JSON.stringify(baseJson.add_input_data))
  // ServiceManager.setDynaTraceLog('DocForm.setJsonData.condition', JSON.stringify(baseJson.condition)) // (ASR210600884_2021.07.20_PJO)
  // ServiceManager.setDynaTraceLog('DocForm.setJsonData.add_input_data', JSON.stringify(baseJson.add_input_data)) // (ASR210600884_2021.07.20_PJO)
  return JSON.stringify(baseJson)
}

/************************************************************************************************
 * Function명 : getOzJsonData
 * 설명       : OZ 전자서명 정보 JsonData에서 Object로 추출
 * @param result
 ************************************************************************************************/
DocForm.getOzJsonData = function (result) {
  let returnJson = Object.assign({}, returnJsonData) // 구조 복제
  try {
    // 출력 값이 Object 일때 String 로 변경
    let returnData
    if (typeof (result) === 'string') {
      console.log('DocForm.getOzJsonData Start1::::')
      returnData = JSON.parse(result) // 입력 Data Object로 변경
    } else {
      console.log('DocForm.getOzJsonData Start2::::')
      returnData = result
    } // end else if
    returnJson.signature = returnData.signature // 전자서명 최종 완료 여부 (true : 최종 완료, false : 미완료

    // returnJson.signature = true // younghee 테스트를 위한 임시코드=>oz앱개발자 김경우님에게 수정 요청했슴 20200623

    // // 전자서명 결과 XML을 Object 로 변경
    // let objXmlData
    // if (typeof (returnData.strXmlData) === 'string') {
    //   console.log('DocForm.getOzJsonData Start3::::')
    //   objXmlData = PSXmlConvertUtil.xml2Object(returnData.strXmlData)
    // } else {
    //   console.log('DocForm.getOzJsonData Start4::::')
    //   objXmlData = returnData.strXmlData
    // } // end else if
    // returnJson.strXmlData = objXmlData // PDF에서 추출한 XMl DATA 원본
    // 서명앱에서 추출한 JSON DATA 가공
    console.log('parsingJSONDocForm:::returnData.signature:' + returnData.signature)
    console.log('parsingJSONDocForm:::returnData.formData:' + JSON.stringify(returnData.formData))
    let parsingJSON = parsingJSONDocForm(returnData.signature, returnData.formData)
    console.log('DocForm.getOzJsonData Start5::::' + JSON.stringify(parsingJSON))
    returnJson.xmlData = parsingJSON.data
    if (parsingJSON.trtRslt === 0) {
      console.log('DocForm.getOzJsonData Start6::::')
      // returnJson.trtRsltMsg = parsingXML.trtRsltMsg
    } // end else if
    // 서명앱에서 추출한 서명정보
    console.log('DocForm.getOzJsonData Start8::::')
    returnJson.signerInfo = returnData.signerInfo

    // 전자서명 파일 CRC 값
    console.log('DocForm.getOzJsonData Start9::::')
    returnJson.fileCrc = returnData.fileCrc
    // 191010 / isDcmWcnstOmit 서면동의생략 : 최종서명 완료이고(signature:true), 서면동의서 촬영을 건너뛰기 한경우에만  'Y' 설정
    // if (returnData.isDcmWcnstOmit !== undefined && returnData.isDcmWcnstOmit === 'Y') {
    //   returnJson.isDcmWcnstOmit = returnData.isDcmWcnstOmit
    //   console.log('DocForm.getOzJsonData Start10::::')
    // } else {
    //   returnJson.isDcmWcnstOmit = 'N'
    //   console.log('DocForm.getOzJsonData Start11::::')
    // }
    // 2020.06 생체인증전자서명관련 추가사항
    // (1) isDcmWcnstOmit" ==> 'Y' : 서면동의서 촬영' 선택하였으나 촬영 스킵, (AS IS 사용) 아래와 같이 변경됨.
    // 서면동의서 선택 후 촬영 스킵 : 'Y' (AS IS)
    // 서면동의서 선택 후 촬영 성공 : 'N', (신규 추가)
    // 지문 촬영 선택 시 : 'F' (신규 추가)
    if (returnData.isDcmWcnstOmit !== undefined) {
      returnJson.isDcmWcnstOmit = returnData.isDcmWcnstOmit
    } else {
      returnJson.isDcmWcnstOmit = 'N'
    }
    // (2) fileHash :  파일의 HASH값(SHA256), signature가 'true'이고 아래 isDcmWcnstOmit가 'F'일 경우만 추출하여 전달예정 (신규 추가 예정)
    if (returnData.fileHash !== undefined) {
      returnJson.fileHash = returnData.fileHash
    }
  } catch (error) {
    console.log('DocForm.getOzJsonData Start12::::')
    returnJson.trtRslt = 0
    returnJson.trtRsltMsg = '전자서명 오류'
  } // end try..catch
  return returnJson
}

/************************************************************************************************
 * Function명 : getOzSevletAddr
 * 설명       : OZ 서블릿 주소 반환
 * @param servlet_addr
 ************************************************************************************************/
DocForm.getOzSevletAddr = function () {
  let servlet_addr = ''
  /*
  if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
    servlet_addr = 'http://wb1.t.tss.samsunglife.kr:9810/OZRepeaterServlet' // 보거디 개발계
    // JsonInputData.servlet_addr = 'http://ap1.t.eform.samsunglife.kr:12120/server' // eform 직접 호출
  } else {
    servlet_addr = window.vue.config.http.baseURI + '/OZRepeaterServlet'
  }
  */
  if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
    servlet_addr = 'http://wb1.t.tss.samsunglife.kr:8810/OZRepeaterServlet'
  } else {
    servlet_addr = window.vue.config.http.baseURI + '/OZRepeaterServlet'
  }
  return servlet_addr
}
/*********************************************************
 * Function명: textToBase64
 * 설명: 문자를 받아 base64로 그려주는 함수
 * Params: N/A
 * Return: N/A
 *********************************************************/
DocForm.textToBase64 = function (text, fontsize = 20) {
  if (!text) {
    text = ''
  }
  text = String(text)
  // bizUtil.getByteLength와 동일 부분
  let iByteLength = 0
  for (let i = 0; i < text.length; i++) {
    let sChar = escape(text.charAt(i))
    if (text.charAt(i) === ' ') {
      iByteLength += 0.75
    } else if (sChar === '.' || sChar === ',' || sChar === ';') {
      iByteLength += 0.5
    } else if (sChar.length === 1) {
      if (sChar >= 'A' && sChar <= 'Z') {
        iByteLength += 1.5
      } else if (sChar === 'i' || sChar === 'l') {
        iByteLength += 0.5
      } else if (sChar >= 'a' && sChar <= 'z') {
        iByteLength += 1.25
      } else if (sChar >= '0' && sChar <= '9') {
        iByteLength += 1
      } else {
        iByteLength += 0.5
      }
    } else if (sChar.indexOf('%u') !== -1) {
      iByteLength += 2
    } else if (sChar.indexOf('%') !== -1) {
      iByteLength += sChar.length / 3
    }
  }

  // bizUtil.getByteLength와 동일 부분
  let canvas = document.createElement('canvas')
  let start = fontsize / 5
  canvas.height = fontsize + fontsize / 4
  canvas.width = iByteLength * fontsize / 2 + start * 2
  canvas = canvas.getContext('2d')
  canvas.textBaseline = 'middle'
  canvas.font = 'bold ' + fontsize + 'px sans-serif' // 글씨체 변경시 수정 부분
  canvas.fillText(text, start, (fontsize + fontsize / 4) / 2, iByteLength * fontsize / 2)
  return canvas.canvas.toDataURL() // 이미지를 Base64형태의 URL로 출력
}
export default DocForm
